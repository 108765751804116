import React from 'react';
import { formatTime } from '../../utils/dateUtils';

const TimerDisplay = ({ seconds, className = '' }) => {
    return (
        <div className={`timer-display ${className}`}>
            {formatTime(seconds)}
        </div>
    );
};

export default TimerDisplay;