import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFile,
    faFolder,
    faUpload,
    faPlus,
    faTrash,
    faEdit,
    faArrowUp,
    faDownload,
    faCut,
    faPaste,
    faGripHorizontal,
    faList,
    faEllipsisV,
    faSearch,
    faSync,
    faHome
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useStorage } from '../../context/StorageContext';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import Card from '../../components/common/Card';

// Définir l'URL de l'API
import config from '../../config';

// URL de base de l'API
const API_URL = config.apiUrl;

const Files = () => {
    const {
        files,
        folders,
        uploadFile,
        createFolder,
        deleteFile,
        deleteFolder,
        moveFile,
        moveFolder,
        renameFile,
        renameFolder,
        fetchFilesInFolder,
        fetchFoldersInFolder,
        isLoading: contextLoading,
        refreshStorage
    } = useStorage();

    const { token } = useAuth();
    const { showNotification } = useNotification();

    // Gestion de l'état local
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderName, setFolderName] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [isCreatingFolder, setIsCreatingFolder] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [itemToRename, setItemToRename] = useState(null);
    const [newName, setNewName] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [viewMode, setViewMode] = useState('grid'); // grid ou list
    const [clipboard, setClipboard] = useState(null); // {items: [], action: 'cut'}
    const fileInputRef = useRef();

    // État local pour stocker les fichiers et dossiers du dossier courant
    const [currentFiles, setCurrentFiles] = useState([]);
    const [currentSubFolders, setCurrentSubFolders] = useState([]);

    const [isDragging, setIsDragging] = useState(false);
    const dropAreaRef = useRef(null);

    // Fonction pour charger le contenu du dossier courant
    const loadCurrentFolderContents = async () => {
        setIsLoading(true);
        try {
            // Charger les fichiers du dossier courant
            const folderFiles = await fetchFilesInFolder(currentFolder);
            setCurrentFiles(folderFiles);

            // Charger les sous-dossiers du dossier courant
            const subFolders = await fetchFoldersInFolder(currentFolder);
            setCurrentSubFolders(subFolders);

            // Mettre à jour le fil d'Ariane
            updateBreadcrumbs();
        } catch (error) {
            console.error('Erreur lors du chargement du contenu du dossier:', error);
            showNotification('Erreur lors du chargement du contenu du dossier');
        } finally {
            setIsLoading(false);
        }
    };

    // Charger le contenu du dossier courant quand il change
    useEffect(() => {
        if (token) {
            loadCurrentFolderContents();
        }
    }, [currentFolder, token]);

    // Rafraîchir les données quand le composant est monté
    useEffect(() => {
        const initialLoad = async () => {
            try {
                await refreshStorage();
                await loadCurrentFolderContents();
            } catch (error) {
                console.error('Erreur lors du chargement initial:', error);
            }
        };

        if (token) {
            initialLoad();
        }
    }, [token]);

    // Mettre à jour le fil d'Ariane
    const updateBreadcrumbs = async () => {
        const crumbs = [];
        let folderId = currentFolder;

        if (folderId) {
            let currentId = folderId;
            while (currentId) {
                const folder = folders.find(f => f.id == currentId);
                if (folder) {
                    crumbs.unshift({
                        id: folder.id,
                        name: folder.name
                    });
                    currentId = folder.parent_folder_id;
                } else {
                    break;
                }
            }
        }

        setBreadcrumbs(crumbs);
    };

    // Fonction de téléchargement qui inclut le token d'authentification
    const handleDownload = async (fileId, fileName) => {
        try {
            setIsLoading(true);
            // Créer un élément a temporaire pour le téléchargement
            const link = document.createElement('a');
            link.href = `${API_URL}/files/${fileId}/download`;
            link.download = fileName;

            fetch(link.href, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Erreur HTTP ${response.status}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    // Créer une URL pour le blob
                    const url = window.URL.createObjectURL(blob);
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();

                    // Nettoyer
                    setTimeout(() => {
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                        setIsLoading(false);
                    }, 100);
                })
                .catch(error => {
                    console.error('Erreur lors du téléchargement:', error);
                    showNotification('Erreur lors du téléchargement du fichier');
                    setIsLoading(false);
                });
        } catch (error) {
            console.error('Erreur lors du téléchargement:', error);
            showNotification('Erreur lors du téléchargement du fichier');
            setIsLoading(false);
        }
    };

    // Fonction pour gérer l'upload de fichiers
    const handleFileUpload = async (e) => {
        const files = e.target.files;
        if (!files || files.length === 0) return;

        try {
            setIsLoading(true);

            // Afficher un message adapté au nombre de fichiers
            showNotification(`Importation de ${files.length} fichier${files.length > 1 ? 's' : ''} en cours...`);

            // Parcourir tous les fichiers sélectionnés
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                console.log(`Upload du fichier ${file.name} dans le dossier: ${currentFolder}`);

                // Utiliser la fonction du contexte pour l'upload
                await uploadFile({
                    file,
                    name: file.name,
                    folderId: currentFolder
                });
            }

            // Recharger les fichiers du dossier courant
            await loadCurrentFolderContents();

            // Afficher une notification adaptée au nombre de fichiers
            showNotification(`${files.length > 1 ? 'Fichiers importés' : 'Fichier importé'} avec succès!`);

            // Réinitialiser le champ file input
            fileInputRef.current.value = null;
        } catch (error) {
            console.error('Erreur lors de l\'upload des fichiers:', error);
            showNotification('Erreur lors de l\'upload des fichiers');
            fileInputRef.current.value = null;
        } finally {
            setIsLoading(false);
        }
    };

    const handleDroppedFiles = async (files) => {
        if (!files || files.length === 0) return;

        try {
            setIsLoading(true);

            // Parcourir tous les fichiers déposés
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                console.log(`Upload du fichier déposé dans le dossier: ${currentFolder}`);

                // Utiliser la fonction du contexte pour l'upload et la mise à jour des états
                await uploadFile({
                    file,
                    name: file.name,
                    folderId: currentFolder
                });
            }

            // Recharger les fichiers du dossier courant
            await loadCurrentFolderContents();

            showNotification(`${files.length > 1 ? 'Fichiers uploadés' : 'Fichier uploadé'} avec succès!`);
        } catch (error) {
            console.error('Erreur lors de l\'upload des fichiers déposés:', error);
            showNotification('Erreur lors de l\'upload des fichiers');
        } finally {
            setIsLoading(false);
            setIsDragging(false);
        }
    };

    // Gestionnaires d'événements pour le glisser-déposer
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDragging) setIsDragging(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Vérifier si le curseur a quitté la zone de dépôt
        if (dropAreaRef.current && !dropAreaRef.current.contains(e.relatedTarget)) {
            setIsDragging(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleDroppedFiles(e.dataTransfer.files);
        }
    };

    // Ajouter ces événements au ref de la zone de dépôt
    useEffect(() => {
        const dropArea = dropAreaRef.current;

        if (dropArea) {
            dropArea.addEventListener('dragover', handleDragOver);
            dropArea.addEventListener('dragenter', handleDragEnter);
            dropArea.addEventListener('dragleave', handleDragLeave);
            dropArea.addEventListener('drop', handleDrop);

            return () => {
                dropArea.removeEventListener('dragover', handleDragOver);
                dropArea.removeEventListener('dragenter', handleDragEnter);
                dropArea.removeEventListener('dragleave', handleDragLeave);
                dropArea.removeEventListener('drop', handleDrop);
            };
        }
    }, [currentFolder]);

    // Fonction pour créer un nouveau dossier
    const handleCreateFolder = async () => {
        if (!folderName.trim()) return;

        try {
            setIsLoading(true);
            await createFolder(folderName, currentFolder);
            setFolderName('');
            setIsCreatingFolder(false);

            // Recharger les sous-dossiers après création
            await loadCurrentFolderContents();

            showNotification('Dossier créé avec succès!');
        } catch (error) {
            console.error('Erreur lors de la création du dossier:', error);
            showNotification('Erreur lors de la création du dossier');
        } finally {
            setIsLoading(false);
        }
    };

    // Navigation vers un dossier
    const navigateToFolder = async (folderId) => {
        setSelectedItems([]);
        setCurrentFolder(folderId);
    };

    // Navigation vers le dossier parent
    const navigateUp = async () => {
        if (!currentFolder) return;

        // Trouver le dossier parent
        const currentFolderObj = folders.find(f => f.id == currentFolder);
        if (!currentFolderObj) return;

        const parentFolder = currentFolderObj.parent_folder_id;
        setCurrentFolder(parentFolder || null);
        setSelectedItems([]);
    };

    // Sélection d'un élément (fichier ou dossier)
    const handleSelectItem = (id, type, event) => {
        // Si Ctrl n'est pas enfoncé, désélectionner tout d'abord
        if (!event.ctrlKey && !event.metaKey) {
            setSelectedItems([]);
        }

        const itemInfo = { id, type };
        const isSelected = selectedItems.some(item => item.id === id && item.type === type);

        if (isSelected) {
            setSelectedItems(selectedItems.filter(item => !(item.id === id && item.type === type)));
        } else {
            setSelectedItems([...selectedItems, itemInfo]);
        }
    };

    // Supprimer les éléments sélectionnés
    // Supprimer les éléments sélectionnés avec mise à jour optimiste
    const handleDelete = async () => {
        if (selectedItems.length === 0) return;

        try {
            // Copie des éléments sélectionnés pour les opérations asynchrones
            const itemsToDelete = [...selectedItems];

            // Mise à jour optimiste: suppression immédiate des éléments de l'interface
            setCurrentFiles(prev =>
                prev.filter(file => !itemsToDelete.some(item =>
                    item.type === 'file' && item.id === file.id
                ))
            );

            setCurrentSubFolders(prev =>
                prev.filter(folder => !itemsToDelete.some(item =>
                    item.type === 'folder' && item.id === folder.id
                ))
            );

            // Vider la sélection immédiatement 
            setSelectedItems([]);

            // Afficher notification
            showNotification('Suppression en cours...');

            // Exécuter les requêtes de suppression en arrière-plan
            setIsLoading(true);

            const deletePromises = itemsToDelete.map(item => {
                if (item.type === 'file') {
                    return deleteFile(item.id);
                } else if (item.type === 'folder') {
                    return deleteFolder(item.id, true);
                }
            });

            // Attendre que toutes les suppressions soient terminées
            await Promise.all(deletePromises);

            // Une fois terminé, recharger pour s'assurer que l'interface est synchronisée
            await loadCurrentFolderContents();

            showNotification('Éléments supprimés avec succès!');
        } catch (error) {
            console.error('Erreur lors de la suppression:', error);
            showNotification('Erreur lors de la suppression. Actualisation nécessaire.');

            // En cas d'erreur, recharger pour restaurer l'état correct
            await loadCurrentFolderContents();
        } finally {
            setIsLoading(false);
        }
    };

    // Couper des éléments (préparation au déplacement)
    const handleCut = () => {
        if (selectedItems.length === 0) return;
        setClipboard({ items: [...selectedItems], action: 'cut' });
        showNotification('Éléments prêts à être déplacés');
    };

    // Coller les éléments coupés
    const handlePaste = async () => {
        if (!clipboard || clipboard.items.length === 0) {
            showNotification('Aucun élément dans le presse-papier');
            return;
        }

        try {
            setIsLoading(true);

            for (const item of clipboard.items) {
                if (item.type === 'file') {
                    await moveFile(item.id, currentFolder);
                } else if (item.type === 'folder') {
                    // Éviter de déplacer un dossier dans lui-même ou ses sous-dossiers
                    if (item.id != currentFolder) {
                        await moveFolder(item.id, currentFolder);
                    } else {
                        showNotification('Impossible de déplacer un dossier dans lui-même');
                    }
                }
            }

            // Recharger après déplacement
            await loadCurrentFolderContents();

            setSelectedItems([]);
            setClipboard(null);
            showNotification('Éléments déplacés avec succès!');
        } catch (error) {
            console.error('Erreur lors du déplacement:', error);
            showNotification('Erreur lors du déplacement');
        } finally {
            setIsLoading(false);
        }
    };

    // Initialiser le renommage d'un élément
    const startRenaming = (item, type) => {
        setItemToRename({ id: item.id, type });
        setNewName(item.name);
        setIsRenaming(true);
    };

    // Renommer un élément
    const handleRename = async () => {
        if (!itemToRename || !newName.trim()) return;

        try {
            setIsLoading(true);

            if (itemToRename.type === 'file') {
                await renameFile(itemToRename.id, newName);
            } else if (itemToRename.type === 'folder') {
                await renameFolder(itemToRename.id, newName);
            }

            // Recharger après renommage
            await loadCurrentFolderContents();

            setIsRenaming(false);
            setItemToRename(null);
            setNewName('');
            showNotification('Élément renommé avec succès!');
        } catch (error) {
            console.error('Erreur lors du renommage:', error);
            showNotification('Erreur lors du renommage');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="files-page">
            <div className="files-container">
                <Card
                    title=""
                    className="files-card"
                    headerContent={null}
                >
                    <div className="files-actions">
                        <button
                            className="btn btn-primary"
                            onClick={() => fileInputRef.current.click()}
                            disabled={isLoading || contextLoading}
                        >
                            <FontAwesomeIcon icon={faUpload} /> Importer
                        </button>
                        <button
                            className="btn"
                            onClick={() => setIsCreatingFolder(true)}
                            disabled={isLoading || contextLoading}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Nouveau dossier
                        </button>

                        {selectedItems.length > 0 && (
                            <>
                                <button
                                    className="btn"
                                    onClick={handleCut}
                                    disabled={isLoading || contextLoading}
                                >
                                    <FontAwesomeIcon icon={faCut} /> Couper
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={handleDelete}
                                    disabled={isLoading || contextLoading}
                                >
                                    <FontAwesomeIcon icon={faTrash} /> Supprimer
                                </button>
                            </>
                        )}

                        {clipboard && (
                            <button
                                className="btn"
                                onClick={handlePaste}
                                disabled={isLoading || contextLoading}
                            >
                                <FontAwesomeIcon icon={faPaste} /> Coller
                            </button>
                        )}

                        <div className="view-mode">
                            <button
                                className="btn"
                                onClick={refreshStorage}
                                disabled={isLoading || contextLoading}
                                title="Rafraîchir"
                            >
                                <FontAwesomeIcon icon={faSync} spin={isLoading || contextLoading} />
                            </button>
                            <button
                                className={viewMode === 'grid' ? 'active' : ''}
                                onClick={() => setViewMode('grid')}
                            >
                                <FontAwesomeIcon icon={faGripHorizontal} />
                            </button>
                            <button
                                className={viewMode === 'list' ? 'active' : ''}
                                onClick={() => setViewMode('list')}
                            >
                                <FontAwesomeIcon icon={faList} />
                            </button>
                        </div>

                        <input
                            type="file"
                            multiple
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                        />
                    </div>

                    {clipboard && (
                        <div className="clipboard-status">
                            <FontAwesomeIcon icon={faCut} />
                            {clipboard.items.length} élément(s) prêt(s) à être déplacé(s)
                        </div>
                    )}

                    <div className="files-breadcrumb">
                        <button
                            className="breadcrumb-item"
                            onClick={() => setCurrentFolder(null)}
                        >
                            <FontAwesomeIcon icon={faHome} /> Racine
                        </button>
                        {breadcrumbs.map((crumb, index) => (
                            <React.Fragment key={crumb.id}>
                                <span className="breadcrumb-separator">/</span>
                                <button
                                    className="breadcrumb-item"
                                    onClick={() => navigateToFolder(crumb.id)}
                                >
                                    {crumb.name}
                                </button>
                            </React.Fragment>
                        ))}
                    </div>

                    <div
                        ref={dropAreaRef}
                        className={`files-list ${viewMode === 'list' ? 'list-view' : ''} ${isDragging ? 'drag-active' : ''}`}
                    >
                        {/* Overlay de glisser-déposer qui apparaît lors du glisser */}
                        {isDragging && (
                            <div className="drop-overlay">
                                <div className="drop-message">
                                    <FontAwesomeIcon icon={faUpload} size="3x" />
                                    <p>Déposez vos fichiers ici</p>
                                </div>
                            </div>
                        )}

                        {currentFolder && (
                            <div className="file-item folder-up" onClick={navigateUp}>
                                <FontAwesomeIcon icon={faArrowUp} className="file-icon-back" />
                                <span className="file-name">Dossier parent</span>
                            </div>
                        )}

                        {/* Affichage des sous-dossiers */}
                        {currentSubFolders.map(folder => (
                            <div
                                key={folder.id}
                                className={`file-item folder ${selectedItems.some(item => item.id === folder.id && item.type === 'folder')
                                    ? 'selected' : ''
                                    }`}
                                onClick={(e) => {
                                    if (e.target.closest('.file-actions') || e.target.closest('.file-select')) return;
                                    if (e.ctrlKey || e.metaKey) {
                                        handleSelectItem(folder.id, 'folder', e);
                                    } else {
                                        navigateToFolder(folder.id);
                                    }
                                }}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    handleSelectItem(folder.id, 'folder', { ctrlKey: false });
                                }}
                            >
                                <div className="file-select">
                                    <input
                                        type="checkbox"
                                        checked={selectedItems.some(item => item.id === folder.id && item.type === 'folder')}
                                        onChange={(e) => handleSelectItem(folder.id, 'folder', e)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </div>
                                <FontAwesomeIcon icon={faFolder} className="file-icon" />
                                <span className="file-name">{folder.name}</span>
                                <div className="file-actions">
                                    <button
                                        className="btn-sm btn-info"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            startRenaming(folder, 'folder');
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button
                                        className="btn-sm btn-danger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // Suppression optimiste - mettre à jour l'UI immédiatement
                                            setCurrentSubFolders(prev =>
                                                prev.filter(f => f.id !== folder.id)
                                            );
                                            showNotification('Suppression en cours...');

                                            // Puis effectuer la vraie suppression en arrière-plan
                                            deleteFolder(folder.id, true)
                                                .catch(error => {
                                                    console.error('Erreur lors de la suppression:', error);
                                                    showNotification('Erreur lors de la suppression');
                                                    // Recharger l'état en cas d'échec pour restaurer le dossier
                                                    loadCurrentFolderContents();
                                                });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                        ))}

                        {/* Affichage des fichiers */}
                        {currentFiles.map(file => (
                            <div
                                key={file.id}
                                className={`file-item ${selectedItems.some(item => item.id === file.id && item.type === 'file')
                                    ? 'selected' : ''
                                    }`}
                                onClick={(e) => {
                                    if (e.target.closest('.file-actions') || e.target.closest('.file-select')) return;
                                    handleSelectItem(file.id, 'file', e);
                                }}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    handleSelectItem(file.id, 'file', { ctrlKey: false });
                                }}
                            >
                                <div className="file-select">
                                    <input
                                        type="checkbox"
                                        checked={selectedItems.some(item => item.id === file.id && item.type === 'file')}
                                        onChange={(e) => handleSelectItem(file.id, 'file', e)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </div>
                                <FontAwesomeIcon icon={faFile} className="file-icon" />
                                <span className="file-name">{file.name}</span>
                                <div className="file-actions">
                                    <button
                                        className="btn-sm btn-primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDownload(file.id, file.name);
                                        }}
                                        disabled={isLoading || contextLoading}
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </button>
                                    <button
                                        className="btn-sm btn-info"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            startRenaming(file, 'file');
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button
                                        className="btn-sm btn-danger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // Suppression optimiste - mettre à jour l'UI immédiatement
                                            setCurrentFiles(prev =>
                                                prev.filter(f => f.id !== file.id)
                                            );
                                            showNotification('Suppression en cours...');

                                            // Puis effectuer la vraie suppression en arrière-plan
                                            deleteFile(file.id)
                                                .catch(error => {
                                                    console.error('Erreur lors de la suppression:', error);
                                                    showNotification('Erreur lors de la suppression');
                                                    // Recharger l'état en cas d'échec pour restaurer le fichier
                                                    loadCurrentFolderContents();
                                                });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                        ))}

                        {currentSubFolders.length === 0 && currentFiles.length === 0 && (
                            <div className="empty-state">
                                <div className="empty-state-drop-hint">
                                    <FontAwesomeIcon icon={faUpload} />
                                    <p>Vous pouvez glisser-déposer vos fichiers ici</p>
                                </div>
                            </div>
                        )}

                        {(isLoading || contextLoading) && (
                            <div className="loading-overlay">
                                <div className="loading-spinner">
                                    <FontAwesomeIcon icon={faSync} spin size="2x" />
                                    <p>Chargement en cours...</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Modal pour créer un nouveau dossier */}
                    {isCreatingFolder && (
                        <>
                            <div className="overlay" onClick={() => setIsCreatingFolder(false)}></div>
                            <div className="folder-creator">
                                <h3 className="creator-title">Nouveau dossier</h3>
                                <input
                                    type="text"
                                    value={folderName}
                                    onChange={(e) => setFolderName(e.target.value)}
                                    placeholder="Nom du dossier"
                                    autoFocus
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleCreateFolder();
                                        } else if (e.key === 'Escape') {
                                            setIsCreatingFolder(false);
                                        }
                                    }}
                                />
                                <div className="creator-actions">
                                    <button
                                        className="btn"
                                        onClick={() => setIsCreatingFolder(false)}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleCreateFolder}
                                    >
                                        Créer
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Modal pour renommer un élément */}
                    {isRenaming && (
                        <>
                            <div className="overlay" onClick={() => {
                                setIsRenaming(false);
                                setItemToRename(null);
                            }}></div>
                            <div className="rename-dialog">
                                <h3 className="creator-title">Renommer</h3>
                                <input
                                    type="text"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    placeholder="Nouveau nom"
                                    autoFocus
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleRename();
                                        } else if (e.key === 'Escape') {
                                            setIsRenaming(false);
                                            setItemToRename(null);
                                        }
                                    }}
                                />
                                <div className="creator-actions">
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            setIsRenaming(false);
                                            setItemToRename(null);
                                        }}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleRename}
                                    >
                                        Renommer
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </Card>
            </div>
        </div>
    );
};

export default Files;