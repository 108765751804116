import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Card = ({ children, title, icon, className = '', link = null, headerContent = null }) => {
    return (
        <div className={`card ${className}`}>
            <div className="card-header">
                <h2 className="card-title">
                    {icon && <FontAwesomeIcon icon={icon} />} {title}
                </h2>
                {headerContent || (link && (
                    <Link to={link.to} className="card-link">
                        {link.text}
                    </Link>
                ))}
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
};

export default Card;