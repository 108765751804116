import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import { useNotification } from '../../context/NotificationContext';

const NoteEditor = () => {
    const { notes, addNote, updateNote, deleteNote } = useStorage();
    const { showNotification } = useNotification();

    const [isActive, setIsActive] = useState(false);
    const [currentNoteId, setCurrentNoteId] = useState(null);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    // Réinitialiser l'éditeur lorsqu'il est rendu
    useEffect(() => {
        window.openNoteEditor = openNoteEditor;
        return () => {
            window.openNoteEditor = undefined;
        };
    }, [notes]);

    // Fonction pour ouvrir l'éditeur de notes
    const openNoteEditor = (noteId = null) => {
        // Réinitialiser l'éditeur
        setCurrentNoteId(null);
        setTitle('');
        setContent('');

        // Si on édite une note existante
        if (noteId) {
            const note = notes.find(note => note.id === noteId);
            if (note) {
                setCurrentNoteId(noteId);
                setTitle(note.title);
                setContent(note.content);
            }
        }

        // Afficher l'éditeur
        setIsActive(true);
    };

    // Fonction pour fermer l'éditeur
    const closeNoteEditor = () => {
        setIsActive(false);
    };

    // Fonction pour enregistrer la note
    const saveNote = () => {
        const trimmedTitle = title.trim();
        const trimmedContent = content.trim();

        if (trimmedTitle && trimmedContent) {
            if (currentNoteId) {
                // Mettre à jour une note existante
                updateNote(currentNoteId, {
                    title: trimmedTitle,
                    content: trimmedContent
                });
                showNotification('Note mise à jour !');
            } else {
                // Créer une nouvelle note
                addNote({
                    id: Date.now(),
                    title: trimmedTitle,
                    content: trimmedContent,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                });
                showNotification('Note créée avec succès !');
            }

            closeNoteEditor();
        }
    };

    // Fonction pour supprimer la note actuelle
    const deleteCurrentNote = () => {
        if (currentNoteId) {
            deleteNote(currentNoteId);
            showNotification('Note supprimée !');
            closeNoteEditor();
        }
    };

    // Gestionnaire d'événement pour la touche Échap
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && isActive) {
                closeNoteEditor();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isActive]);

    // Si l'éditeur n'est pas actif, ne rien afficher
    if (!isActive) return null;

    return (
        <div
            className={`note-editor-container ${isActive ? 'active' : ''}`}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    closeNoteEditor();
                }
            }}
        >
            <div className="note-editor">
                <div className="note-editor-header">
                    <h3 className="note-editor-title">
                        {currentNoteId ? 'Modifier la note' : 'Créer une note'}
                    </h3>
                    <button
                        className="note-editor-close"
                        onClick={closeNoteEditor}
                    >
                        &times;
                    </button>
                </div>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Titre de la note"
                    autoFocus
                />
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Contenu de la note..."
                />
                <div className="note-editor-actions">
                    {currentNoteId && (
                        <button
                            className="btn btn-danger"
                            onClick={deleteCurrentNote}
                        >
                            <FontAwesomeIcon icon={faTrash} /> Supprimer
                        </button>
                    )}
                    <button
                        className="btn btn-success"
                        onClick={saveNote}
                    >
                        <FontAwesomeIcon icon={faSave} /> Enregistrer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NoteEditor;