import React from 'react';
import { faChartLine, faHistory, faTasks } from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import {
    formatDuration,
    formatShortDate,
    formatTime24Hour,
    getStartOfDay,
    getStartOfWeek,
    getStartOfMonth
} from '../../utils/dateUtils';
import Card from '../../components/common/Card';

const Stats = () => {
    const { workSessions, tasks } = useStorage();

    // Calcul des statistiques temporelles
    const now = new Date();
    const startOfDay = getStartOfDay(now);
    const startOfWeek = getStartOfWeek(now);
    const startOfMonth = getStartOfMonth(now);

    const todaySessions = workSessions.filter(session => new Date(session.startTime) >= startOfDay);
    const weekSessions = workSessions.filter(session => new Date(session.startTime) >= startOfWeek);
    const monthSessions = workSessions.filter(session => new Date(session.startTime) >= startOfMonth);

    const todayDuration = todaySessions.reduce((total, session) => total + session.duration, 0);
    const weekDuration = weekSessions.reduce((total, session) => total + session.duration, 0);
    const monthDuration = monthSessions.reduce((total, session) => total + session.duration, 0);

    // Statistiques des tâches
    const activeCount = tasks.filter(task => !task.completed).length;
    const completedCount = tasks.filter(task => task.completed).length;
    const totalCount = tasks.length;
    const completionRate = totalCount > 0 ? Math.round((completedCount / totalCount) * 100) : 0;

    // Trier les sessions par date (plus récentes en premier)
    const sortedSessions = [...workSessions].sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

    return (
        <div>

            <div className="stats-container">
                {/* Stats Overview Card */}
                <Card
                    title="Aperçu"
                    icon={faChartLine}
                    className="stats-overview-card"
                >
                    <div className="stats-grid">
                        <div className="stat-item">
                            <div className="stat-title">Aujourd'hui</div>
                            <div className="stat-value">{formatDuration(todayDuration)}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Cette Semaine</div>
                            <div className="stat-value">{formatDuration(weekDuration)}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Ce Mois</div>
                            <div className="stat-value">{formatDuration(monthDuration)}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Total Sessions</div>
                            <div className="stat-value">{workSessions.length}</div>
                        </div>
                    </div>
                </Card>

                {/* Sessions List Card */}
                <Card
                    title="Historique des Sessions"
                    icon={faHistory}
                    className="sessions-list-card"
                >
                    <div className="sessions-table-container">
                        <table className="sessions-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Heure de début</th>
                                    <th>Heure de fin</th>
                                    <th>Durée</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedSessions.length > 0 ? (
                                    sortedSessions.map(session => {
                                        const startTime = new Date(session.startTime);
                                        const endTime = new Date(session.endTime);

                                        return (
                                            <tr key={session.id}>
                                                <td>{formatShortDate(startTime)}</td>
                                                <td>{formatTime24Hour(startTime)}</td>
                                                <td>{formatTime24Hour(endTime)}</td>
                                                <td>{formatDuration(session.duration)}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="empty-state">Aucune session enregistrée</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Card>

                {/* Tasks Completion Card */}
                <Card
                    title="Tâches"
                    icon={faTasks}
                    className="tasks-stats-card"
                >
                    <div className="stats-grid task-stats-grid">
                        <div className="stat-item">
                            <div className="stat-title">Tâches actives</div>
                            <div className="stat-value">{activeCount}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Tâches terminées</div>
                            <div className="stat-value">{completedCount}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Total</div>
                            <div className="stat-value">{totalCount}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Taux d'achèvement</div>
                            <div className="stat-value">{completionRate}%</div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Stats;