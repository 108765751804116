import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';

const EventForm = ({ onClose, onSave, onDelete, event, initialDate }) => {
    // États pour les champs du formulaire
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [color, setColor] = useState('#4f46e5');
    const [allDay, setAllDay] = useState(false);

    // Initialiser les valeurs du formulaire si on modifie un événement existant
    useEffect(() => {
        if (event) {
            const start = new Date(event.start);
            const end = new Date(event.end);

            setTitle(event.title || '');
            setDescription(event.description || '');
            setStartDate(formatDateForInput(start));
            setStartTime(formatTimeForInput(start));
            setEndDate(formatDateForInput(end));
            setEndTime(formatTimeForInput(end));
            setColor(event.color || '#4f46e5');
            setAllDay(event.allDay || false);
        } else if (initialDate) {
            // Si initialDate est fourni, utilisez-le pour remplir les dates par défaut
            setStartDate(formatDateForInput(initialDate));
            setStartTime('09:00');

            const endDate = new Date(initialDate);
            endDate.setHours(initialDate.getHours() + 1);
            setEndDate(formatDateForInput(endDate));
            setEndTime('10:00');
        }
    }, [event, initialDate]);

    // Fonction pour formater une date pour un input date (YYYY-MM-DD)
    const formatDateForInput = (date) => {
        if (!date) return '';
        const d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
    };

    // Fonction pour formater une heure pour un input time (HH:MM)
    const formatTimeForInput = (date) => {
        if (!date) return '';
        const d = new Date(date);
        return `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
    };

    // Fonction pour enregistrer l'événement
    const handleSave = (e) => {
        e.preventDefault();

        // Valider les données
        if (!title) {
            alert('Veuillez saisir un titre pour l\'événement');
            return;
        }

        if (!startDate) {
            alert('Veuillez sélectionner une date de début');
            return;
        }

        // Créer les objets Date pour le début et la fin
        let start, end;

        if (allDay) {
            // Pour les événements toute la journée, ignorer l'heure
            start = new Date(startDate);
            start.setHours(0, 0, 0, 0);

            end = new Date(endDate || startDate);
            end.setHours(23, 59, 59, 999);
        } else {
            // Pour les événements avec une heure spécifique
            const [startHours, startMinutes] = startTime.split(':').map(Number);
            start = new Date(startDate);
            start.setHours(startHours, startMinutes, 0, 0);

            if (endDate && endTime) {
                const [endHours, endMinutes] = endTime.split(':').map(Number);
                end = new Date(endDate);
                end.setHours(endHours, endMinutes, 0, 0);
            } else {
                // Par défaut, l'événement dure 1 heure
                end = new Date(start);
                end.setHours(end.getHours() + 1);
            }
        }

        // Créer l'objet événement
        const eventData = {
            title,
            description,
            start: start.toISOString(),
            end: end.toISOString(),
            color,
            allDay
        };

        // Envoyer l'événement au parent
        onSave(eventData);
    };

    return (
        <div className="event-form-container">
            <div className="event-form">
                <div className="event-form-header">
                    <h3 className="event-form-title">
                        {event ? 'Modifier l\'événement' : 'Nouvel événement'}
                    </h3>
                    <button
                        className="event-form-close"
                        onClick={onClose}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <form onSubmit={handleSave}>
                    <div className="form-group">
                        <label htmlFor="event-title">Titre</label>
                        <input
                            type="text"
                            id="event-title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Titre de l'événement"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="event-description">Description</label>
                        <textarea
                            id="event-description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description (optionnel)"
                            rows="3"
                        />
                    </div>

                    <div className="form-group checkbox-group">
                        <label className="checkbox-label">
                            <input
                                type="checkbox"
                                checked={allDay}
                                onChange={(e) => setAllDay(e.target.checked)}
                            />
                            Toute la journée
                        </label>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="event-start-date">Date de début</label>
                            <input
                                type="date"
                                id="event-start-date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                            />
                        </div>

                        {!allDay && (
                            <div className="form-group">
                                <label htmlFor="event-start-time">Heure de début</label>
                                <input
                                    type="time"
                                    id="event-start-time"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                    required
                                />
                            </div>
                        )}
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="event-end-date">Date de fin</label>
                            <input
                                type="date"
                                id="event-end-date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                min={startDate}
                            />
                        </div>

                        {!allDay && (
                            <div className="form-group">
                                <label htmlFor="event-end-time">Heure de fin</label>
                                <input
                                    type="time"
                                    id="event-end-time"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="event-color">Couleur</label>
                        <input
                            type="color"
                            id="event-color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            className="event-color-picker"
                        />
                    </div>

                    <div className="event-form-actions">
                        {onDelete && (
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={onDelete}
                            >
                                <FontAwesomeIcon icon={faTrash} /> Supprimer
                            </button>
                        )}

                        <button
                            type="submit"
                            className="btn btn-success"
                        >
                            <FontAwesomeIcon icon={faSave} /> Enregistrer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EventForm;