import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

// Icon components for reuse
const ArrowRightIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-btn-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M5 12h14M12 5l7 7-7 7" />
    </svg>
);

const ClockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10" />
        <polyline points="12 6 12 12 16 14" />
    </svg>
);

const TasksIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
        <line x1="9" y1="9" x2="15" y2="9" />
        <line x1="9" y1="14" x2="15" y2="14" />
        <line x1="9" y1="19" x2="15" y2="19" />
    </svg>
);

const NotesIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
        <polyline points="13 2 13 9 20 9" />
    </svg>
);

const CalendarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
        <line x1="16" y1="2" x2="16" y2="6" />
        <line x1="8" y1="2" x2="8" y2="6" />
        <line x1="3" y1="10" x2="21" y2="10" />
    </svg>
);

const FolderIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
    </svg>
);

const HeadphonesIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3 18v-6a9 9 0 0 1 18 0v6" />
        <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z" />
    </svg>
);

const StatsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="18" y1="20" x2="18" y2="10" />
        <line x1="12" y1="20" x2="12" y2="4" />
        <line x1="6" y1="20" x2="6" y2="14" />
    </svg>
);

const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="jw-pricing-feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="20 6 9 17 4 12" />
    </svg>
);

// Animation variants for framer-motion
const fadeInUp = {
    hidden: { opacity: 0, y: 30 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.6,
            ease: "easeOut"
        }
    }
};

const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1
        }
    }
};

const popIn = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: "spring",
            stiffness: 400,
            damping: 10
        }
    }
};



// Feature Card Component
const FeatureCard = ({ icon, title, description, index }) => {
    return (
        <motion.div
            className="jw-feature"
            variants={fadeInUp}
            custom={index}
            whileHover={{
                y: -10,
                boxShadow: "0 10px 25px -5px rgba(16, 185, 129, 0.2)",
                borderColor: "rgba(16, 185, 129, 0.4)"
            }}
            transition={{ duration: 0.2 }}
        >
            <motion.div
                className="jw-feature-icon-wrapper"
                whileHover={{ scale: 1.1, rotate: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
            >
                {icon}
            </motion.div>
            <h3 className="jw-feature-title">{title}</h3>
            <p className="jw-feature-text">{description}</p>
        </motion.div>
    );
};

// Workflow Step Component
const WorkflowStep = ({ number, title, description, index }) => {
    return (
        <motion.div
            className="jw-step"
            variants={fadeInUp}
            custom={index}
        >
            <motion.div
                className="jw-step-number"
                whileHover={{ scale: 1.1, rotate: 10 }}
                transition={{ type: "spring", stiffness: 300 }}
            >
                {number}
            </motion.div>
            <div className="jw-step-content">
                <h3 className="jw-step-title">{title}</h3>
                <p className="jw-step-text">{description}</p>
            </div>
        </motion.div>
    );
};

// Pricing Feature Component
const PricingFeature = ({ text, isLight = false }) => {
    return (
        <motion.li
            className="jw-pricing-feature"
            whileHover={{ x: 5 }}
            transition={{ duration: 0.2 }}
        >
            <CheckIcon />
            <span className={isLight ? 'jw-pricing-feature-text-light' : ''}>{text}</span>
        </motion.li>
    );
};

// Testimonial Card Component
const TestimonialCard = ({ content, author, role, index }) => {
    return (
        <motion.div
            className="jw-testimonial"
            variants={popIn}
            custom={index}
            whileHover={{
                y: -10,
                boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.1)"
            }}
            transition={{ duration: 0.2 }}
        >
            <div className="jw-testimonial-quote">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="rgba(16, 185, 129, 0.2)" stroke="none">
                    <path d="M3.691 6.292C5.094 4.771 7.217 4 10.067 4v2.134c-1.977 0-3.384.402-4.224 1.203-.42.399-.705.872-.86 1.416h5.084V18H3.691V9.134c0-1.103.336-2.044 1-2.842zm12 0C17.094 4.771 19.217 4 22.067 4v2.134c-1.977 0-3.384.402-4.224 1.203-.42.399-.705.872-.86 1.416h5.084V18h-6.376V9.134c0-1.103.336-2.044 1-2.842z" />
                </svg>
            </div>
            <p className="jw-testimonial-content">{content}</p>
            <div className="jw-testimonial-author-container">
                <div className="jw-testimonial-avatar">
                    {author.charAt(0)}
                </div>
                <div>
                    <p className="jw-testimonial-author">{author}</p>
                    <p className="jw-testimonial-role">{role}</p>
                </div>
            </div>
        </motion.div>
    );
};

// Hamburger Menu Component for mobile
const HamburgerMenu = ({ isOpen, toggleMenu }) => {
    return (
        <button className="jw-hamburger" onClick={toggleMenu} aria-label="Menu">
            <div className={`jw-hamburger-line ${isOpen ? 'active' : ''}`}></div>
            <div className={`jw-hamburger-line ${isOpen ? 'active' : ''}`}></div>
            <div className={`jw-hamburger-line ${isOpen ? 'active' : ''}`}></div>
        </button>
    );
};

const LandingPage = () => {
    const headerRef = useRef(null);
    const [currentYear] = useState(new Date().getFullYear());
    const [menuOpen, setMenuOpen] = useState(false);
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [activeSection, setActiveSection] = useState('hero');
    const [hasScrolled, setHasScrolled] = useState(false);

    // Toggle mobile menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Toggle theme
    const toggleTheme = () => {
        setIsDarkTheme(!isDarkTheme);
        document.body.classList.toggle('jw-dark-theme');
    };

    // Observer for scroll animations and header
    useEffect(() => {
        const handleScroll = () => {
            // Header scroll effect
            if (headerRef.current) {
                if (window.scrollY > 50) {
                    headerRef.current.classList.add('jw-header-scrolled');
                    setHasScrolled(true);
                } else {
                    headerRef.current.classList.remove('jw-header-scrolled');
                    setHasScrolled(false);
                }
            }

            // Determine active section for navigation
            const sections = ['hero', 'features', 'workflow', 'pricing', 'testimonials'];
            let currentSection = 'hero';

            sections.forEach(section => {
                const element = document.getElementById(section);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    if (rect.top <= 200 && rect.bottom >= 200) {
                        currentSection = section;
                    }
                }
            });

            setActiveSection(currentSection);
        };

        // Apply on initial load
        handleScroll();

        // Add scroll listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuOpen && !event.target.closest('.jw-mobile-menu') && !event.target.closest('.jw-hamburger')) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [menuOpen]);

    // Prevent scroll when mobile menu is open
    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [menuOpen]);

    return (
        <div className={`jw-landing ${isDarkTheme ? 'jw-dark-theme' : ''}`}>
            {/* Header */}
            <header ref={headerRef} className="jw-header">
                <div className="jw-container">
                    <div className="jw-header-inner">
                        <motion.a
                            href="#hero"
                            className="jw-logo"
                            animate={{ scale: hasScrolled ? 0.9 : 1 }}
                            transition={{ duration: 0.3 }}
                        >
                            <span className="jw-logo-accent">Jojo</span><span className="jw-logo-secondary">Work</span>
                        </motion.a>

                        <nav className="jw-nav jw-desktop-only">
                            <ul className="jw-nav-list">
                                <li className="jw-nav-item">
                                    <a href="#features" className={`jw-nav-link ${activeSection === 'features' ? 'active' : ''}`}>
                                        Fonctionnalités
                                    </a>
                                </li>
                                <li className="jw-nav-item">
                                    <a href="#pricing" className={`jw-nav-link ${activeSection === 'pricing' ? 'active' : ''}`}>
                                        Tarifs
                                    </a>
                                </li>
                                <li className="jw-nav-item">
                                    <a href="#testimonials" className={`jw-nav-link ${activeSection === 'testimonials' ? 'active' : ''}`}>
                                        Témoignages
                                    </a>
                                </li>
                            </ul>
                        </nav>

                        <div className="jw-header-actions">
                            <motion.button
                                onClick={toggleTheme}
                                className="jw-theme-toggle"
                                aria-label="Changer de thème"
                                whileHover={{ scale: 1.1, rotate: 180 }}
                                transition={{ duration: 0.3 }}
                            >
                                {isDarkTheme ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="jw-theme-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <circle cx="12" cy="12" r="5"></circle>
                                        <line x1="12" y1="1" x2="12" y2="3"></line>
                                        <line x1="12" y1="21" x2="12" y2="23"></line>
                                        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                        <line x1="1" y1="12" x2="3" y2="12"></line>
                                        <line x1="21" y1="12" x2="23" y2="12"></line>
                                        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="jw-theme-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                                    </svg>
                                )}
                            </motion.button>
                            <motion.a
                                href="/login"
                                className="jw-btn jw-btn-link jw-desktop-only"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Connexion
                            </motion.a>
                            <motion.a
                                href="/register"
                                className="jw-btn jw-btn-primary jw-desktop-only"
                                whileHover={{ scale: 1.05, y: -2 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                S'inscrire
                            </motion.a>
                            <HamburgerMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
                        </div>
                    </div>
                </div>
            </header>

            {/* Mobile Menu */}
            <div className={`jw-mobile-menu ${menuOpen ? 'active' : ''}`}>
                <nav className="jw-mobile-nav">
                    <ul className="jw-mobile-nav-list">
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? 0 : 50 }}
                            transition={{ delay: 0.1 }}
                        >
                            <a href="#hero" onClick={toggleMenu}>Accueil</a>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? 0 : 50 }}
                            transition={{ delay: 0.2 }}
                        >
                            <a href="#features" onClick={toggleMenu}>Fonctionnalités</a>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? 0 : 50 }}
                            transition={{ delay: 0.4 }}
                        >
                            <a href="#pricing" onClick={toggleMenu}>Tarifs</a>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? 0 : 50 }}
                            transition={{ delay: 0.5 }}
                        >
                            <a href="#testimonials" onClick={toggleMenu}>Témoignages</a>
                        </motion.li>
                        <li className="jw-mobile-nav-divider"></li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? 0 : 50 }}
                            transition={{ delay: 0.6 }}
                        >
                            <a href="/login" className="jw-mobile-btn">Connexion</a>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? 0 : 50 }}
                            transition={{ delay: 0.7 }}
                        >
                            <a href="/register" className="jw-mobile-btn jw-mobile-btn-primary">S'inscrire</a>
                        </motion.li>
                    </ul>
                </nav>
            </div>

            {/* Hero Section */}
            <section id="hero" className="jw-hero">
                <div className="jw-container">
                    <div className="jw-hero-grid">
                        <motion.div
                            className="jw-hero-content"
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        >
                            <motion.div
                                className="jw-badge"
                                initial="initial"
                                animate="animate"
                            >
                                ✨ Boostez votre productivité
                            </motion.div>

                            <motion.h1
                                className="jw-hero-title"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.2, duration: 0.6 }}
                            >
                                L'outil qui simplifie votre façon de travailler
                            </motion.h1>

                            <motion.p
                                className="jw-hero-subtitle"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.3, duration: 0.6 }}
                            >
                                Jojo Work vous aide à rester concentré, organiser vos tâches et gérer votre temps efficacement
                                avec une interface minimaliste et intuitive.
                            </motion.p>

                            <motion.div
                                className="jw-hero-actions"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.4, duration: 0.6 }}
                            >
                                <motion.a
                                    href="/login"
                                    className="jw-btn jw-btn-primary jw-btn-lg jw-btn-with-glow"
                                    whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(16, 185, 129, 0.6)" }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Essayer gratuitement
                                    <ArrowRightIcon />
                                </motion.a>
                                <motion.a
                                    href="#features"
                                    className="jw-btn jw-btn-outline"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Découvrir les fonctionnalités
                                </motion.a>
                            </motion.div>
                        </motion.div>

                        <motion.div
                            className="jw-hero-image-wrapper"
                            initial={{ opacity: 0, x: 30 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8, delay: 0.3 }}
                        >
                            <motion.div
                                className="jw-hero-image-bg"
                                animate={{
                                    rotate: [-2, 0, -2],
                                    scale: [1, 1.02, 1]
                                }}
                                transition={{
                                    duration: 8,
                                    repeat: Infinity,
                                    ease: "easeInOut"
                                }}
                            ></motion.div>
                            <motion.img
                                src="interface.png"
                                alt="Interface Jojo Work"
                                className="jw-hero-image"
                                animate={{
                                    rotate: [1, 0, 1],
                                    y: [0, -10, 0]
                                }}
                                transition={{
                                    duration: 6,
                                    repeat: Infinity,
                                    ease: "easeInOut"
                                }}
                                whileHover={{
                                    scale: 1.05,
                                    rotate: 0,
                                    transition: {
                                        type: "spring",
                                        stiffness: 300
                                    }
                                }}
                            />
                        </motion.div>
                    </div>
                </div>

                <div className="jw-scroll-indicator">
                    <motion.div
                        className="jw-scroll-icon"
                        animate={{ y: [0, 10, 0] }}
                        transition={{ repeat: Infinity, duration: 1.5 }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </motion.div>
                </div>
            </section>

            {/* Features Section */}
            <section id="features" className="jw-section jw-section-white">
                <div className="jw-container">
                    <motion.div
                        className="jw-section-header"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, margin: "-100px" }}
                        variants={fadeInUp}
                    >
                        <h2 className="jw-section-title">Fonctionnalités principales</h2>
                        <p className="jw-section-subtitle">
                            Des outils conçus pour améliorer votre productivité quotidienne et vous aider à atteindre vos objectifs.
                        </p>
                    </motion.div>

                    <motion.div
                        className="jw-features-grid"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, margin: "-100px" }}
                        variants={staggerContainer}
                    >
                        <FeatureCard
                            icon={<ClockIcon />}
                            title="Chronomètre"
                            description="Utilisez la technique Pomodoro pour alterner entre des sessions de travail ciblées et des pauses bien méritées."
                            index={0}
                        />

                        <FeatureCard
                            icon={<TasksIcon />}
                            title="Tâches"
                            description="Organisez vos tâches avec un système simple et efficace. Suivez votre progression en temps réel."
                            index={1}
                        />

                        <FeatureCard
                            icon={<NotesIcon />}
                            title="Notes"
                            description="Capturez vos idées instantanément avec un éditeur de notes minimaliste et puissant."
                            index={2}
                        />

                        <FeatureCard
                            icon={<HeadphonesIcon />}
                            title="Playlist"
                            description="Concentrez-vous avec des playlists musicales conçues pour améliorer votre productivité."
                            index={3}
                        />

                        <FeatureCard
                            icon={<FolderIcon />}
                            title="Fichiers"
                            description="Stockez et accédez à vos documents importants, où que vous soyez, depuis tous vos appareils."
                            index={4}
                        />

                        <FeatureCard
                            icon={<CalendarIcon />}
                            title="Agenda"
                            description="Visualisez votre emploi du temps et planifiez vos journées avec simplicité et clarté."
                            index={5}
                        />
                    </motion.div>
                </div>
            </section>

            {/* Pricing Section */}
            <section id="pricing" className="jw-section jw-section-white">
                <div className="jw-container">
                    <motion.div
                        className="jw-section-header"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, margin: "-100px" }}
                        variants={fadeInUp}
                    >
                        <h2 className="jw-section-title">Plans adaptés à vos besoins</h2>
                        <p className="jw-section-subtitle">
                            Choisissez l'offre qui vous convient, sans engagement
                        </p>
                    </motion.div>

                    <div className="jw-pricing-grid">
                        <motion.div
                            className="jw-pricing-card"
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, margin: "-100px" }}
                            transition={{ duration: 0.5 }}
                            whileHover={{ y: -10 }}
                        >
                            <div className="jw-pricing-content">
                                <h3 className="jw-pricing-title">Plan Gratuit</h3>
                                <p className="jw-pricing-subtitle">Parfait pour démarrer</p>

                                <div className="jw-pricing-price">
                                    <span className="jw-pricing-amount">0€</span>
                                    <span className="jw-pricing-period">/ mois</span>
                                </div>

                                <ul className="jw-pricing-features">
                                    <PricingFeature text="Chronomètre basique" />
                                    <PricingFeature text="Gestion des tâches limitée" />
                                    <PricingFeature text="Notes rapides" />
                                    <PricingFeature text="Agenda intégré" />
                                    <PricingFeature text="Playlist intégré" />
                                    <PricingFeature text="100 mo de stockage" />
                                </ul>

                                <motion.a
                                    href="#"
                                    className="jw-btn jw-btn-outline"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Commencer gratuitement
                                </motion.a>
                            </div>
                        </motion.div>

                        <motion.div
                            className="jw-pricing-card jw-pricing-card-gradient"
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, margin: "-100px" }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            whileHover={{ y: -10 }}
                        >
                            <div className="jw-pricing-badge">Populaire</div>
                            <div className="jw-pricing-content">
                                <h3 className="jw-pricing-title">Plan Premium</h3>
                                <p className="jw-pricing-subtitle jw-pricing-subtitle-light">Pour les professionnels</p>

                                <div className="jw-pricing-price">
                                    <span className="jw-pricing-amount">2.99€</span>
                                    <span className="jw-pricing-period jw-pricing-period-light">/ mois</span>
                                </div>

                                <ul className="jw-pricing-features">
                                    <PricingFeature text="Chronomètre avancé" isLight />
                                    <PricingFeature text="Gestion des tâches complète" isLight />
                                    <PricingFeature text="Notes avancées avec tags" isLight />
                                    <PricingFeature text="Agenda intégré" isLight />
                                    <PricingFeature text="Playlist intégré" isLight />
                                    <PricingFeature text="2 GB de stockage" isLight />
                                    <PricingFeature text="Statistiques détaillées" isLight />
                                    <PricingFeature text="Support prioritaire" isLight />
                                </ul>

                                <motion.a
                                    href="/login"
                                    className="jw-btn jw-btn-white"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Essayer 14 jours gratuits
                                </motion.a>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            <section id="testimonials" className="jw-section jw-section-gray">
                <div className="jw-container">
                    <motion.div
                        className="jw-section-header"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, margin: "-100px" }}
                        variants={fadeInUp}
                    >
                        <h2 className="jw-section-title">Ce qu'en disent nos utilisateurs</h2>
                        <p className="jw-section-subtitle">
                            Découvrez comment Jojo Work a transformé la façon de travailler de nos utilisateurs
                        </p>
                    </motion.div>

                    <motion.div
                        className="jw-testimonials-grid"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, margin: "-100px" }}
                        variants={staggerContainer}
                    >
                        <TestimonialCard
                            content="J'utilise Jojo Work tous les jours pour gérer mes tâches. L'interface est claire et facile à utiliser."
                            author="Marc R."
                            role="Ingénieur"
                            index={0}
                        />

                        <TestimonialCard
                            content="Le chronomètre est vraiment pratique pour me concentrer sur mon travail. Je fais des pauses plus régulièrement."
                            author="Julie B."
                            role="Comptable"
                            index={1}
                        />

                        <TestimonialCard
                            content="Simple et efficace. C'est exactement ce qu'il me fallait pour organiser mes journées."
                            author="Paul T."
                            role="Commercial"
                            index={2}
                        />
                    </motion.div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="jw-section jw-section-gradient">
                <div className="jw-container">
                    <motion.div
                        className="jw-cta-container"
                        initial={{ opacity: 0, scale: 0.9 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true, margin: "-100px" }}
                        transition={{ duration: 0.6 }}
                    >
                        <h2 className="jw-section-title jw-title-light">Prêt à améliorer votre productivité ?</h2>
                        <p className="jw-section-subtitle jw-subtitle-light">
                            Commencez dès aujourd'hui et découvrez comment Jojo Work peut transformer votre façon de travailler.
                        </p>
                        <div className="jw-cta-actions">
                            <motion.a
                                href="/login"
                                className="jw-btn jw-btn-white jw-btn-with-glow"
                                whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(255, 255, 255, 0.5)" }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Essayer gratuitement
                                <ArrowRightIcon />
                            </motion.a>
                        </div>
                    </motion.div>
                </div>
            </section>

            {/* Footer */}
            <footer className="jw-footer">
                <div className="jw-container">


                    <div className="jw-footer-bottom">
                        <p className="jw-copyright">
                            © {currentYear} Jojo Work. Tous droits réservés.
                        </p>
                        <div className="jw-footer-legal">
                            <a href="#">Confidentialité</a>
                            <a href="#">Mentions légales</a>
                            <a href="#">Cookies</a>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Back to Top Button */}
            <motion.a
                href="#hero"
                className="jw-back-to-top"
                initial={{ opacity: 0, y: 20 }}
                animate={{
                    opacity: hasScrolled ? 1 : 0,
                    y: hasScrolled ? 0 : 20
                }}
                transition={{ duration: 0.3 }}
                whileHover={{ y: -5 }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="18 15 12 9 6 15"></polyline>
                </svg>
            </motion.a>
        </div>
    );
};

export default LandingPage;