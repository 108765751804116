import React from 'react';

const TimerModes = ({ currentMode, setMode }) => {
    return (
        <div className="timer-modes">
            <button
                className={`timer-mode ${currentMode === 'work' ? 'active' : ''}`}
                onClick={() => setMode('work')}
            >
                Travail
            </button>
            <button
                className={`timer-mode ${currentMode === 'short-break' ? 'active' : ''}`}
                onClick={() => setMode('short-break')}
            >
                Pause courte
            </button>
            <button
                className={`timer-mode ${currentMode === 'long-break' ? 'active' : ''}`}
                onClick={() => setMode('long-break')}
            >
                Pause longue
            </button>
        </div>
    );
};

export default TimerModes;