import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faChartBar, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import { useNotification } from '../../context/NotificationContext';
import Card from '../../components/common/Card';
import TaskItem from '../../components/tasks/TaskItem';

const Tasks = () => {
    const { tasks, addTask, toggleTask, deleteTask } = useStorage();
    const { showNotification } = useNotification();
    const [filter, setFilter] = useState('all');
    const taskInputRef = useRef();

    // Filtrer les tâches en fonction du filtre actuel
    const filteredTasks = tasks.filter(task => {
        if (filter === 'active') return !task.completed;
        if (filter === 'completed') return task.completed;
        return true; // 'all'
    }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Compter les tâches actives et terminées
    const activeCount = tasks.filter(task => !task.completed).length;
    const completedCount = tasks.filter(task => task.completed).length;
    const totalCount = tasks.length;
    const completionRate = totalCount > 0 ? Math.round((completedCount / totalCount) * 100) : 0;

    // Ajouter une nouvelle tâche
    const handleAddTask = () => {
        const taskText = taskInputRef.current.value.trim();

        if (taskText) {
            const newTask = {
                id: Date.now(),
                text: taskText,
                completed: false,
                createdAt: new Date().toISOString()
            };

            addTask(newTask);
            taskInputRef.current.value = '';
            showNotification('Tâche ajoutée avec succès !');
        }
    };

    // Gérer l'appui sur la touche Entrée
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddTask();
        }
    };

    // Message d'état vide en fonction du filtre
    const emptyStateMessage = () => {
        switch (filter) {
            case 'active':
                return 'Aucune tâche en cours. Bravo !';
            case 'completed':
                return 'Aucune tâche terminée. Continuez vos efforts !';
            default:
                return 'Aucune tâche. Ajoutez-en une !';
        }
    };

    return (
        <div>
            <div className="tasks-container">
                {/* Tasks Card */}
                <Card
                    title="Tâches"
                    icon={faTasks}
                    className="tasks-card"
                    headerContent={
                        <div className="task-filters">
                            <button
                                className={`task-filter ${filter === 'all' ? 'active' : ''}`}
                                onClick={() => setFilter('all')}
                            >
                                Toutes
                            </button>
                            <button
                                className={`task-filter ${filter === 'active' ? 'active' : ''}`}
                                onClick={() => setFilter('active')}
                            >
                                En cours
                            </button>
                            <button
                                className={`task-filter ${filter === 'completed' ? 'active' : ''}`}
                                onClick={() => setFilter('completed')}
                            >
                                Terminées
                            </button>
                        </div>
                    }
                >
                    <div className="task-input-group">
                        <input
                            type="text"
                            ref={taskInputRef}
                            className="task-input"
                            placeholder="Ajouter une nouvelle tâche..."
                            onKeyPress={handleKeyPress}
                        />
                        <button
                            className="task-submit"
                            onClick={handleAddTask}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>

                    <ul className="task-list">
                        {filteredTasks.length > 0 ? (
                            filteredTasks.map(task => (
                                <TaskItem
                                    key={task.id}
                                    task={task}
                                    toggleTask={toggleTask}
                                    deleteTask={deleteTask}
                                />
                            ))
                        ) : (
                            <li className="empty-state">
                                {emptyStateMessage()}
                            </li>
                        )}
                    </ul>
                </Card>

                {/* Task Stats Card */}
                <Card
                    title="Statistiques des tâches"
                    icon={faChartBar}
                    className="task-stats-card"
                >
                    <div className="stats-grid task-stats-grid">
                        <div className="stat-item">
                            <div className="stat-title">Tâches actives</div>
                            <div className="stat-value">{activeCount}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Tâches terminées</div>
                            <div className="stat-value">{completedCount}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Total</div>
                            <div className="stat-value">{totalCount}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Taux d'achèvement</div>
                            <div className="stat-value">{completionRate}%</div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Tasks;