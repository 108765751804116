import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const TaskItem = ({ task, toggleTask, deleteTask, showDelete = true }) => {
    const [isCompleteAnimation, setIsCompleteAnimation] = useState(false);

    const handleToggle = () => {
        if (!task.completed) {
            setIsCompleteAnimation(true);
            setTimeout(() => setIsCompleteAnimation(false), 1000);
        }
        toggleTask(task.id);
    };

    return (
        <li
            className={`task-item ${task.completed ? 'completed' : ''} ${isCompleteAnimation ? 'task-complete-animation' : ''}`}
            data-id={task.id}
        >
            <input
                type="checkbox"
                className="task-checkbox"
                checked={task.completed}
                onChange={handleToggle}
            />
            <span className="task-text">{task.text}</span>
            {showDelete && deleteTask && (
                <button
                    className="task-delete"
                    onClick={() => deleteTask(task.id)}
                    title="Supprimer"
                >
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            )}
        </li>
    );
};

export default TaskItem;