import React, { createContext, useContext, useState } from 'react';

// Créer le contexte de notification
const NotificationContext = createContext();

// Hook personnalisé pour utiliser le contexte de notification
export function useNotification() {
    return useContext(NotificationContext);
}

// Fournisseur du contexte
export function NotificationProvider({ children }) {
    const [notification, setNotification] = useState(null);

    // Fonction pour afficher une notification
    const showNotification = (message) => {
        // Supprimer une notification existante
        setNotification(null);

        // Créer une nouvelle notification
        setTimeout(() => {
            setNotification(message);

            // Cacher la notification après un délai
            setTimeout(() => {
                setNotification(null);
            }, 3000);
        }, 10);
    };

    // Fonction pour demander la permission pour les notifications du navigateur
    const requestNotificationPermission = () => {
        if (Notification.permission !== 'granted' &&
            Notification.permission !== 'denied' &&
            !localStorage.getItem('notificationPermissionRequested')) {

            Notification.requestPermission().then(() => {
                localStorage.setItem('notificationPermissionRequested', 'true');
            });
        }
    };

    // Fonction pour envoyer une notification du navigateur
    const sendBrowserNotification = (title, body) => {
        if (Notification.permission === 'granted') {
            new Notification(title, {
                body: body,
                icon: 'https://cdn-icons-png.flaticon.com/512/6049/6049268.png'
            });
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    new Notification(title, {
                        body: body,
                        icon: 'https://cdn-icons-png.flaticon.com/512/6049/6049268.png'
                    });
                }
            });
        }
    };

    // Objet de valeur qui sera fourni par le contexte
    const value = {
        notification,
        showNotification,
        requestNotificationPermission,
        sendBrowserNotification
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
            {notification && (
                <div className={`notification ${notification ? 'show' : ''}`}>
                    <i className="fas fa-check-circle"></i> {notification}
                </div>
            )}
        </NotificationContext.Provider>
    );
}