import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import { useNotification } from '../../context/NotificationContext';
import Card from '../../components/common/Card';
import NoteItem from '../../components/notes/NoteItem';

const Notes = () => {
    const { notes, deleteNote } = useStorage();
    const { showNotification } = useNotification();

    // Trier les notes par date de modification (plus récentes en premier)
    const sortedNotes = [...notes].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    // Ouvrir l'éditeur de notes
    const openNoteEditor = (noteId = null) => {
        if (window.openNoteEditor) {
            window.openNoteEditor(noteId);
        }
    };

    // Supprimer une note
    const handleDeleteNote = (id) => {
        deleteNote(id);
        showNotification('Note supprimée !');
    };

    return (
        <div>

            <div className="notes-container">
                <Card
                    title="Notes"
                    icon={faStickyNote}
                    className="notes-card"
                    headerContent={
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => openNoteEditor()}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Ajouter une note
                        </button>
                    }
                >
                    <div className="notes-grid">
                        {sortedNotes.length > 0 ? (
                            sortedNotes.map(note => (
                                <NoteItem
                                    key={note.id}
                                    note={note}
                                    openEditor={openNoteEditor}
                                    deleteNote={handleDeleteNote}
                                />
                            ))
                        ) : (
                            <div className="empty-state">
                                <FontAwesomeIcon icon={faStickyNote} style={{ fontSize: '2rem', marginBottom: '1rem' }} />
                                <p>Aucune note. Créez votre première note !</p>
                            </div>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Notes;