import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { StorageProvider } from './context/StorageContext';
import { NotificationProvider } from './context/NotificationContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import { TimerProvider } from './context/TimerContext';
import Sidebar from './components/layout/Sidebar';
import Home from './pages/Main/Home';
import Timer from './pages/Main/Timer';
import Tasks from './pages/Main/Tasks';
import Notes from './pages/Main/Notes';
import Playlist from './pages/Main/Playlist';
import Stats from './pages/Main/Stats';
import Agenda from './pages/Main/Agenda';
import NoteEditor from './components/notes/NoteEditor';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Files from './pages/Main/Files';
import LandingPage from './pages/LandingPage';

// Composant pour les routes protégées
const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

function App() {
  // État pour suivre si la sidebar est réduite
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    localStorage.getItem('sidebarCollapsed') === 'true'
  );

  // Effet pour mettre à jour la classe du corps quand la sidebar change
  useEffect(() => {
    const sidebarElement = document.querySelector('.jw-sidebar');

    if (sidebarCollapsed) {
      document.body.classList.add('sidebar-collapsed');
      if (sidebarElement) {
        sidebarElement.classList.add('jw-sidebar-collapsed');
      }
    } else {
      document.body.classList.remove('sidebar-collapsed');
      if (sidebarElement) {
        sidebarElement.classList.remove('jw-sidebar-collapsed');
      }
    }
  }, [sidebarCollapsed]);

  // Fonction pour basculer l'état de la sidebar
  const toggleSidebar = () => {
    const newState = !sidebarCollapsed;
    setSidebarCollapsed(newState);
    localStorage.setItem('sidebarCollapsed', newState);
  };

  return (
    <Router>
      <ThemeProvider>
        <NotificationProvider>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <StorageProvider>
                      <TimerProvider>
                        <div className="app-container">
                          <Sidebar
                            collapsed={sidebarCollapsed}
                            toggleSidebar={toggleSidebar}
                          />
                          <main className="main-content">
                            <Routes>
                              <Route path="/home" element={<Home />} />
                              <Route path="/timer" element={<Timer />} />
                              <Route path="/tasks" element={<Tasks />} />
                              <Route path="/notes" element={<Notes />} />
                              <Route path="/playlist" element={<Playlist />} />
                              <Route path="/agenda" element={<Agenda />} />
                              <Route path="/stats" element={<Stats />} />
                              <Route path="/files" element={<Files />} />
                            </Routes>
                          </main>
                          <NoteEditor />
                        </div>
                      </TimerProvider>
                    </StorageProvider>
                  </PrivateRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </NotificationProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;