import React, { createContext, useState, useEffect, useContext } from 'react';

// Créer le contexte
const ThemeContext = createContext();

// Liste des thèmes disponibles (facile à étendre)
export const THEMES = {
    LIGHT: 'light',
    DARK: 'dark',
    // Futurs thèmes
    BLUE: 'blue',
    // GREEN: 'green',
    // HIGH_CONTRAST: 'high-contrast',
};

// Hook personnalisé pour utiliser le contexte du thème
export function useTheme() {
    return useContext(ThemeContext);
}

// Fournisseur du contexte
export function ThemeProvider({ children }) {
    // Récupérer le thème depuis localStorage ou utiliser light par défaut
    const [currentTheme, setCurrentTheme] = useState(
        localStorage.getItem('theme') || THEMES.LIGHT
    );

    // Mettre à jour l'attribut data-theme et localStorage quand le thème change
    useEffect(() => {
        // Mettre à jour l'attribut data-theme sur le document
        document.documentElement.setAttribute('data-theme', currentTheme);

        // Pour compatibilité avec l'ancien système
        if (currentTheme === THEMES.DARK) {
            document.body.classList.add('dark-mode');
            document.body.classList.add('jw-dark-theme');
        } else {
            document.body.classList.remove('dark-mode');
            document.body.classList.remove('jw-dark-theme');
        }

        // Enregistrer dans localStorage
        localStorage.setItem('theme', currentTheme);
    }, [currentTheme]);

    // Fonction pour déterminer si c'est le thème sombre (pour compatibilité)
    const isDarkMode = currentTheme === THEMES.DARK;

    // Basculer entre les thèmes light et dark (pour compatibilité)
    const toggleDarkMode = () => {
        setCurrentTheme(prevTheme =>
            prevTheme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK
        );
    };

    // Changer de thème avec n'importe quel thème disponible
    const setTheme = (theme) => {
        if (Object.values(THEMES).includes(theme)) {
            setCurrentTheme(theme);
        }
    };

    // Valeur du contexte
    const value = {
        currentTheme,
        setTheme,
        toggleDarkMode,
        darkMode: isDarkMode, // Pour compatibilité avec l'ancien code
        THEMES
    };

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
}