import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useStorage } from './StorageContext';
import { useNotification } from './NotificationContext';

// Créer le contexte
const TimerContext = createContext();

// Hook personnalisé
export function useTimer() {
    return useContext(TimerContext);
}

// Fournisseur du contexte
export function TimerProvider({ children }) {
    const {
        settings,
        addWorkSession
    } = useStorage();
    const { showNotification, sendBrowserNotification } = useNotification();

    // États pour le timer
    const [seconds, setSeconds] = useState(settings.workDuration);
    const [isRunning, setIsRunning] = useState(false);
    const [currentMode, setCurrentMode] = useState('work');
    const [progress, setProgress] = useState(0);

    // Références pour les données qui doivent persister entre les rendus
    const startTimeRef = useRef(null);
    const intervalRef = useRef(null);
    const durationRef = useRef(0);
    const targetDurationRef = useRef(settings.workDuration);

    // Mettre à jour le mode du timer
    const setMode = (mode) => {
        if (isRunning) {
            if (window.confirm('Voulez-vous vraiment changer de mode ? Votre session en cours sera perdue.')) {
                stopTimer(false);
                updateTimerMode(mode);
            }
        } else {
            updateTimerMode(mode);
        }
    };

    // Mettre à jour le mode du timer
    const updateTimerMode = (mode) => {
        setCurrentMode(mode);

        let duration = settings.workDuration;
        if (mode === 'short-break') {
            duration = settings.shortBreakDuration;
        } else if (mode === 'long-break') {
            duration = settings.longBreakDuration;
        }

        targetDurationRef.current = duration;
        setSeconds(duration);
        setProgress(0);
        durationRef.current = 0;
    };

    // Démarrer le timer
    const startTimer = () => {
        if (!isRunning) {
            startTimeRef.current = new Date();
            durationRef.current = 0;
            setIsRunning(true);

            console.log('Timer démarré avec:', {
                mode: currentMode,
                startTime: startTimeRef.current,
                targetDuration: targetDurationRef.current
            });

            intervalRef.current = setInterval(() => {
                setSeconds(prevSeconds => {
                    const newSeconds = prevSeconds - 1;
                    durationRef.current += 1;

                    const newProgress = ((targetDurationRef.current - newSeconds) / targetDurationRef.current) * 100;
                    setProgress(newProgress);

                    if (newSeconds <= 0) {
                        saveSession();
                        return 0;
                    }

                    return newSeconds;
                });
            }, 1000);
        }
    };

    // Fonction séparée pour enregistrer la session
    const saveSession = () => {
        // Nettoyer le timer
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        // Jouer un son de notification
        try {
            const audio = new Audio('https://assets.mixkit.co/sfx/preview/mixkit-alarm-digital-clock-beep-989.mp3');
            audio.play();
        } catch (error) {
            console.log('Notification sonore non disponible');
        }

        // Envoyer une notification du navigateur
        const message = currentMode === 'work'
            ? 'Session terminée ! Prenez une pause'
            : 'Pause terminée ! Reprenez le travail';

        sendBrowserNotification('Jojo Work', message);

        // Si c'était une session de travail, l'enregistrer
        if (currentMode === 'work' && startTimeRef.current) {
            const endTime = new Date();
            const duration = Math.max(durationRef.current, 1); // Au moins 1 seconde

            const session = {
                startTime: startTimeRef.current.toISOString(),
                endTime: endTime.toISOString(),
                duration: duration
            };

            console.log('Enregistrement de session:', session);

            addWorkSession(session)
                .then(result => {
                    console.log('Session enregistrée avec succès:', result);
                    showNotification('Session terminée et enregistrée !');
                })
                .catch(error => {
                    console.error('Erreur lors de l\'enregistrement:', error);
                    showNotification('Erreur lors de l\'enregistrement de la session');
                });
        } else if (currentMode === 'work') {
            console.warn('Session de travail terminée, mais startTime est manquant');
            showNotification('Session terminée mais non enregistrée (erreur)');
        } else {
            showNotification('Pause terminée !');
        }

        // Gérer les transitions automatiques
        if (currentMode === 'work' && settings.autoStartBreaks) {
            const newMode = (durationRef.current / targetDurationRef.current) % 4 === 0
                ? 'long-break'
                : 'short-break';
            setTimeout(() => {
                updateTimerMode(newMode);
                setTimeout(startTimer, 500);
            }, 500);
        } else if (currentMode !== 'work' && settings.autoStartPomodoros) {
            setTimeout(() => {
                updateTimerMode('work');
                setTimeout(startTimer, 500);
            }, 500);
        }

        // Réinitialiser l'état
        setIsRunning(false);
        startTimeRef.current = null;
        durationRef.current = 0;
    };

    // Mettre en pause le timer
    const pauseTimer = () => {
        if (isRunning && intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setIsRunning(false);
        }
    };

    // Arrêter le timer
    const stopTimer = (record = true) => {
        if (record && currentMode === 'work' && startTimeRef.current && durationRef.current > 30) {
            const endTime = new Date();

            const session = {
                startTime: startTimeRef.current.toISOString(),
                endTime: endTime.toISOString(),
                duration: durationRef.current
            };

            console.log('Enregistrement de session (arrêt manuel):', session);

            addWorkSession(session)
                .then(result => {
                    console.log('Session enregistrée avec succès:', result);
                    showNotification('Session enregistrée avec succès !');
                })
                .catch(error => {
                    console.error('Erreur lors de l\'enregistrement:', error);
                    showNotification('Erreur lors de l\'enregistrement de la session');
                });
        }

        // Nettoyer et réinitialiser
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        setIsRunning(false);
        startTimeRef.current = null;
        durationRef.current = 0;
        updateTimerMode(currentMode);
    };

    // Mise à jour des paramètres
    useEffect(() => {
        if (!isRunning) {
            updateTimerMode(currentMode);
        }
    }, [settings]);

    // Nettoyage
    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const value = {
        seconds,
        isRunning,
        currentMode,
        progress,
        targetDuration: targetDurationRef.current,
        startTimer,
        pauseTimer,
        stopTimer,
        setMode
    };

    return (
        <TimerContext.Provider value={value}>
            {children}
        </TimerContext.Provider>
    );
}