import React from 'react';
import { formatShortDate, formatDuration } from '../../utils/dateUtils';

const SessionItem = ({ session }) => {
    return (
        <div className="stat-item session-item">
            <div className="stat-title">{formatShortDate(session.startTime)}</div>
            <div className="stat-value">{formatDuration(session.duration)}</div>
        </div>
    );
};

export default SessionItem;