import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome, faClock, faTasks, faStickyNote,
    faMusic, faChartPie, faBars, faMoon, faSun,
    faTimes, faChevronUp, faChevronDown, faPlay, faPause, faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../../context/ThemeContext';
import { formatDate } from '../../utils/dateUtils';
import { motion } from 'framer-motion';

// Cette section a été supprimée car nous utilisons maintenant un bouton flottant

const Sidebar = () => {
    // États pour la sidebar
    const [collapsed, setCollapsed] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    // Contexte de thème
    const { darkMode, toggleDarkMode } = useTheme();

    // États pour le mini lecteur YouTube
    const [currentVideoId, setCurrentVideoId] = useState(null);
    const [currentVideoTitle, setCurrentVideoTitle] = useState('');
    const [miniPlayerCollapsed, setMiniPlayerCollapsed] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const iframeRef = useRef(null);
    const playerRef = useRef(null);
    const timeUpdateIntervalRef = useRef(null);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [videoDuration, setVideoDuration] = useState(0);
    const [hoverPosition, setHoverPosition] = useState(null);

    // État pour la date actuelle
    const [currentDate, setCurrentDate] = useState(formatDate(new Date()));

    // Gérer le redimensionnement de la fenêtre
    useEffect(() => {
        const handleResize = () => {
            const mobile = window.innerWidth <= 768;
            setIsMobile(mobile);

            // Fermer le menu mobile automatiquement si on passe en mode desktop
            if (!mobile && mobileMenuOpen) {
                setMobileMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [mobileMenuOpen]);

    // Toggle pour la sidebar en mode desktop
    const toggleSidebar = () => {
        if (isMobile) {
            setMobileMenuOpen(!mobileMenuOpen);
        } else {
            setCollapsed(!collapsed);
        }
    };

    // Toggle pour le menu mobile
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    // Fermer le menu mobile quand on clique sur un lien
    const closeMobileMenu = () => {
        if (isMobile) {
            setMobileMenuOpen(false);
        }
    };

    // Fonction pour mettre à jour le temps actuel
    const updateCurrentTime = () => {
        if (playerRef.current && typeof playerRef.current.getCurrentTime === 'function') {
            try {
                const time = playerRef.current.getCurrentTime();
                const duration = playerRef.current.getDuration();
                setCurrentTime(time);

                // Calculer le pourcentage de progression
                if (duration > 0) {
                    const percentage = (time / duration) * 100;
                    setProgressPercentage(percentage);
                }

                // Émettre un événement pour les autres composants
                window.dispatchEvent(new CustomEvent('youtubeTimeUpdate', { detail: time }));
            } catch (error) {
                console.error("Erreur lors de la récupération du temps actuel:", error);
            }
        }
    };

    const handleProgressBarClick = (e) => {
        if (!playerRef.current || typeof playerRef.current.getDuration !== 'function') return;

        try {
            const progressBar = e.currentTarget;
            const rect = progressBar.getBoundingClientRect();
            const clickX = e.clientX - rect.left;
            const progressBarWidth = rect.width;

            // Calculer le pourcentage du clic
            const clickPercentage = (clickX / progressBarWidth) * 100;

            // Convertir en secondes
            const duration = playerRef.current.getDuration();
            const seekToTime = (clickPercentage / 100) * duration;

            // Se positionner
            playerRef.current.seekTo(seekToTime, true);

            // Mettre à jour les états
            setCurrentTime(seekToTime);
            setProgressPercentage(clickPercentage);

            // Émettre un événement
            window.dispatchEvent(new CustomEvent('youtubeTimeUpdate', { detail: seekToTime }));

            // Sauvegarder la position
            localStorage.setItem('currentVideoPosition', seekToTime.toString());
        } catch (error) {
            console.error("Erreur lors du positionnement de la vidéo:", error);
        }
    };

    // Démarrer le suivi du temps
    const startTimeTracking = () => {
        // Arrêter tout intervalle existant d'abord
        if (timeUpdateIntervalRef.current) {
            clearInterval(timeUpdateIntervalRef.current);
        }

        // Créer un nouvel intervalle
        timeUpdateIntervalRef.current = setInterval(updateCurrentTime, 1000);
    };

    // Arrêter le suivi du temps
    const stopTimeTracking = () => {
        if (timeUpdateIntervalRef.current) {
            clearInterval(timeUpdateIntervalRef.current);
            timeUpdateIntervalRef.current = null;
        }
    };

    const handleProgressBarHover = (e) => {
        if (!playerRef.current || typeof playerRef.current.getDuration !== 'function') return;

        try {
            const progressBar = e.currentTarget;
            const rect = progressBar.getBoundingClientRect();
            const hoverX = e.clientX - rect.left;
            const progressBarWidth = rect.width;

            // Calculer le pourcentage de la position du survol
            const hoverPercentage = (hoverX / progressBarWidth) * 100;
            setHoverPosition(hoverPercentage);
        } catch (error) {
            console.error("Erreur lors du calcul de la position de survol:", error);
        }
    };

    const handleProgressBarLeave = () => {
        setHoverPosition(null);
    };

    // Charger la vidéo depuis localStorage
    useEffect(() => {
        const videoId = localStorage.getItem('currentlyPlayingVideo');
        const videoTitle = localStorage.getItem('currentlyPlayingVideoTitle');
        const playerState = localStorage.getItem('miniPlayerCollapsed');
        const timePosition = localStorage.getItem('currentVideoPosition');

        if (videoId) {
            setCurrentVideoId(videoId);
            setCurrentVideoTitle(videoTitle || 'En lecture');

            // Si une position de temps était sauvegardée
            if (timePosition) {
                setCurrentTime(parseFloat(timePosition));
            }
        }

        if (playerState === 'true') {
            setMiniPlayerCollapsed(true);
        }

        return () => {
            // Nettoyage: sauvegarder la position actuelle
            if (playerRef.current && typeof playerRef.current.getCurrentTime === 'function') {
                try {
                    const currentPos = playerRef.current.getCurrentTime();
                    localStorage.setItem('currentVideoPosition', currentPos.toString());
                } catch (error) {
                    console.error("Erreur lors de la sauvegarde de la position:", error);
                }
            }

            // Et arrêter le suivi du temps
            stopTimeTracking();
        };
    }, []);

    // Mettre à jour la date
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(formatDate(new Date()));
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    // Initialiser l'API YouTube
    useEffect(() => {
        if (!currentVideoId) return;

        const initializePlayer = () => {
            if (!iframeRef.current) return;

            if (playerRef.current) {
                try {
                    playerRef.current.destroy();
                } catch (error) {
                    console.error("Erreur lors de la destruction du player:", error);
                }
                playerRef.current = null;
            }

            try {
                // Modification des paramètres du lecteur YouTube pour cacher l'interface native
                // À remplacer dans la fonction d'initialisation du lecteur

                playerRef.current = new window.YT.Player('jw-youtube-player', {
                    videoId: currentVideoId,
                    events: {
                        'onReady': (event) => {
                            // Récupérer la durée de la vidéo
                            try {
                                const duration = event.target.getDuration();
                                setVideoDuration(duration); // Ajoutez cette ligne pour définir l'état

                                // Émettre un événement pour les autres composants
                                window.dispatchEvent(new CustomEvent('youtubeDurationChange', {
                                    detail: duration
                                }));
                            } catch (error) {
                                console.error("Erreur lors de la récupération de la durée:", error);
                            }

                            // Si on a un temps sauvegardé, se positionner
                            const savedTime = localStorage.getItem('currentVideoPosition');
                            if (savedTime) {
                                const timePosition = parseFloat(savedTime);
                                event.target.seekTo(timePosition, true);
                            }

                            if (isPlaying) {
                                event.target.playVideo();
                                startTimeTracking();
                            }
                        },
                        'onStateChange': (event) => {
                            // YT.PlayerState values: -1 (unstarted), 0 (ended), 1 (playing), 2 (paused), 3 (buffering), 5 (video cued)
                            const newIsPlaying = event.data === window.YT.PlayerState.PLAYING;
                            setIsPlaying(newIsPlaying);

                            // Émettre un événement pour les autres composants
                            window.dispatchEvent(new CustomEvent('youtubePlayStateChange', {
                                detail: newIsPlaying
                            }));

                            // Gérer le suivi du temps
                            if (newIsPlaying) {
                                startTimeTracking();
                            } else {
                                stopTimeTracking();

                                // Sauvegarder la position actuelle lors de la pause
                                if (event.data === window.YT.PlayerState.PAUSED) {
                                    try {
                                        const currentPos = event.target.getCurrentTime();
                                        localStorage.setItem('currentVideoPosition', currentPos.toString());
                                    } catch (error) {
                                        console.error("Erreur lors de la sauvegarde de la position:", error);
                                    }
                                }
                            }
                        }
                    },
                    playerVars: {
                        'autoplay': isPlaying ? 1 : 0,
                        'enablejsapi': 1,
                        'rel': 0,
                        'modestbranding': 1,
                        'playsinline': 1,
                        'controls': 0,       // Masquer les contrôles YouTube
                        'showinfo': 0,       // Masquer les informations de la vidéo
                        'disablekb': 1,      // Désactiver les raccourcis clavier
                        'fs': 0,             // Désactiver le bouton plein écran
                        'iv_load_policy': 3, // Masquer les annotations
                        'autohide': 1        // Masquer les contrôles automatiquement
                    }
                });
            } catch (error) {
                console.error("Erreur lors de l'initialisation du lecteur YouTube:", error);
            }
        };

        if (window.YT && window.YT.Player) {
            initializePlayer();
        } else {
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            window.onYouTubeIframeAPIReady = initializePlayer;
        }

        return () => {
            window.onYouTubeIframeAPIReady = null;

            if (playerRef.current) {
                // Sauvegarder la position actuelle avant de détruire
                try {
                    const currentPos = playerRef.current.getCurrentTime();
                    localStorage.setItem('currentVideoPosition', currentPos.toString());
                } catch (error) {
                    console.error("Erreur lors de la sauvegarde de la position:", error);
                }

                try {
                    playerRef.current.destroy();
                } catch (error) {
                    console.error("Erreur lors de la destruction du player:", error);
                }
                playerRef.current = null;
            }

            stopTimeTracking();
        };
    }, [currentVideoId]);

    // API globale pour le mini-player
    useEffect(() => {
        // Fonction pour lire une vidéo
        window.playSidebarVideo = (videoId, videoTitle) => {
            console.log("Lecture de la vidéo:", videoId, videoTitle);
            if (!videoId) return;

            // Si c'est une nouvelle vidéo, réinitialiser la position
            if (currentVideoId !== videoId) {
                localStorage.removeItem('currentVideoPosition');
                setCurrentTime(0);
            }

            setCurrentVideoId(videoId);
            setCurrentVideoTitle(videoTitle || 'En lecture');
            setIsPlaying(true);

            // Émettre un événement pour les autres composants
            window.dispatchEvent(new CustomEvent('youtubeVideoChange', { detail: videoId }));
            window.dispatchEvent(new CustomEvent('youtubePlayStateChange', { detail: true }));

            if (playerRef.current) {
                if (currentVideoId === videoId) {
                    // Même vidéo, juste reprendre la lecture
                    try {
                        playerRef.current.playVideo();
                        startTimeTracking();
                    } catch (error) {
                        console.error("Erreur lors de la lecture de la vidéo:", error);
                    }
                } else {
                    // Nouvelle vidéo
                    try {
                        playerRef.current.loadVideoById({
                            videoId: videoId,
                            startSeconds: 0
                        });
                    } catch (error) {
                        console.error("Erreur lors du chargement de la nouvelle vidéo:", error);
                    }
                }
            }

            localStorage.setItem('currentlyPlayingVideo', videoId);
            localStorage.setItem('currentlyPlayingVideoTitle', videoTitle || '');
        };

        // Fonction pour mettre en pause
        window.pauseSidebarVideo = () => {
            if (playerRef.current && typeof playerRef.current.pauseVideo === 'function') {
                try {
                    playerRef.current.pauseVideo();
                    setIsPlaying(false);

                    // Sauvegarder la position actuelle
                    const currentPos = playerRef.current.getCurrentTime();
                    localStorage.setItem('currentVideoPosition', currentPos.toString());

                    // Arrêter le suivi du temps
                    stopTimeTracking();

                    // Émettre un événement pour les autres composants
                    window.dispatchEvent(new CustomEvent('youtubePlayStateChange', { detail: false }));
                } catch (error) {
                    console.error("Erreur lors de la pause de la vidéo:", error);
                }
            }
        };

        // Fonction pour reprendre la lecture
        window.resumeSidebarVideo = () => {
            if (playerRef.current && typeof playerRef.current.playVideo === 'function') {
                try {
                    playerRef.current.playVideo();
                    setIsPlaying(true);

                    // Redémarrer le suivi du temps
                    startTimeTracking();

                    // Émettre un événement pour les autres composants
                    window.dispatchEvent(new CustomEvent('youtubePlayStateChange', { detail: true }));
                } catch (error) {
                    console.error("Erreur lors de la reprise de la vidéo:", error);
                }
            }
        };

        // Fonction pour reprendre à un timecode spécifique
        window.seekSidebarVideo = (timeSeconds) => {
            if (playerRef.current && typeof playerRef.current.seekTo === 'function') {
                try {
                    playerRef.current.seekTo(timeSeconds, true);

                    // Mettre à jour le temps actuel
                    setCurrentTime(timeSeconds);
                    localStorage.setItem('currentVideoPosition', timeSeconds.toString());

                    // Émettre un événement pour les autres composants
                    window.dispatchEvent(new CustomEvent('youtubeTimeUpdate', { detail: timeSeconds }));
                } catch (error) {
                    console.error("Erreur lors du positionnement de la vidéo:", error);
                }
            }
        };

        // Fonction pour obtenir le temps actuel
        window.getSidebarCurrentTime = () => {
            if (playerRef.current && typeof playerRef.current.getCurrentTime === 'function') {
                try {
                    return playerRef.current.getCurrentTime();
                } catch (error) {
                    console.error("Erreur lors de la récupération du temps actuel:", error);
                }
            }
            return null;
        };

        // Fonction pour obtenir l'état de lecture
        window.isSidebarPlaying = () => {
            return isPlaying;
        };

        // Fonction pour fermer le lecteur
        window.closeSidebarPlayer = () => {
            closeMiniPlayer();
        };

        return () => {
            // Nettoyage des fonctions globales
            window.playSidebarVideo = undefined;
            window.pauseSidebarVideo = undefined;
            window.resumeSidebarVideo = undefined;
            window.seekSidebarVideo = undefined;
            window.getSidebarCurrentTime = undefined;
            window.isSidebarPlaying = undefined;
            window.closeSidebarPlayer = undefined;

            // Arrêter le suivi du temps
            stopTimeTracking();
        };
    }, [currentVideoId]);

    // Contrôles du lecteur
    const closeMiniPlayer = () => {
        // Sauvegarder la position actuelle avant de fermer
        if (playerRef.current && typeof playerRef.current.getCurrentTime === 'function') {
            try {
                const currentPos = playerRef.current.getCurrentTime();
                localStorage.setItem('currentVideoPosition', currentPos.toString());
            } catch (error) {
                console.error("Erreur lors de la sauvegarde de la position:", error);
            }
        }

        if (playerRef.current) {
            try {
                playerRef.current.stopVideo();
                playerRef.current.destroy();
            } catch (error) {
                console.error("Erreur lors de la fermeture du player:", error);
            }
            playerRef.current = null;
        }

        setCurrentVideoId(null);
        setCurrentVideoTitle('');
        setIsPlaying(false);
        setCurrentTime(0);

        // Arrêter le suivi du temps
        stopTimeTracking();

        localStorage.removeItem('currentlyPlayingVideo');
        localStorage.removeItem('currentlyPlayingVideoTitle');
        // Ne pas supprimer currentVideoPosition pour permettre de reprendre plus tard

        // Émettre des événements pour les autres composants
        window.dispatchEvent(new CustomEvent('youtubeVideoChange', { detail: null }));
        window.dispatchEvent(new CustomEvent('youtubePlayStateChange', { detail: false }));
    };

    const toggleMiniPlayer = () => {
        const newState = !miniPlayerCollapsed;
        setMiniPlayerCollapsed(newState);
        localStorage.setItem('miniPlayerCollapsed', newState.toString());
    };

    const togglePlayback = () => {
        if (!playerRef.current) return;

        try {
            if (isPlaying) {
                playerRef.current.pauseVideo();
                stopTimeTracking();

                // Sauvegarder la position
                const currentPos = playerRef.current.getCurrentTime();
                localStorage.setItem('currentVideoPosition', currentPos.toString());
            } else {
                playerRef.current.playVideo();
                startTimeTracking();
            }

            // setIsPlaying sera mis à jour via l'événement onStateChange
        } catch (error) {
            console.error("Erreur lors du contrôle de la lecture:", error);
        }
    };

    // Formater le temps
    const formatTime = (seconds) => {
        seconds = Math.floor(seconds || 0);
        const minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <>
            <aside className={`jw-sidebar ${collapsed ? 'jw-sidebar-collapsed' : ''} ${isMobile ? 'jw-sidebar-mobile' : ''} ${mobileMenuOpen ? 'jw-sidebar-visible' : ''} ${darkMode ? 'jw-dark-theme' : ''}`}>
                <div className="jw-sidebar-header">
                    <div className="jw-logo">
                        <span className="jw-logo-accent">Jojo</span>
                        <span className="jw-logo-secondary">Work</span>
                    </div>
                    {!isMobile && (
                        <button className="jw-sidebar-toggle" onClick={toggleSidebar} aria-label="Toggle sidebar">
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                    )}
                </div>

                <nav className="jw-sidebar-nav">
                    <ul>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/home" end onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faHome} />
                                <span>Accueil</span>
                            </NavLink>
                        </li>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/timer" onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faClock} />
                                <span>Chronomètre</span>
                            </NavLink>
                        </li>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/tasks" onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faTasks} />
                                <span>Tâches</span>
                            </NavLink>
                        </li>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/notes" onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faStickyNote} />
                                <span>Notes</span>
                            </NavLink>
                        </li>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/playlist" onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faMusic} />
                                <span>Playlist</span>
                            </NavLink>
                        </li>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/files" onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faChartPie} />
                                <span>Fichiers</span>
                            </NavLink>
                        </li>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/agenda" onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                                <span>Agenda</span>
                            </NavLink>
                        </li>
                        <li className="jw-nav-item-sidebar">
                            <NavLink to="/stats" onClick={closeMobileMenu}>
                                <FontAwesomeIcon icon={faChartPie} />
                                <span>Statistiques</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>

                <div className="jw-sidebar-footer">
                    {currentVideoId && (
                        <div className="jw-sidebar-mini-player">
                            <div className="jw-sidebar-mini-player-header">
                                <span className="jw-mini-player-title" title={currentVideoTitle}>
                                    {currentVideoTitle}
                                </span>

                                <div className="jw-mini-player-controls">
                                    <button
                                        onClick={togglePlayback}
                                        className={`jw-playback-control ${isPlaying ? 'playing' : ''}`}
                                        title={isPlaying ? "Pause" : "Play"}
                                    >
                                        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                                    </button>
                                    <button
                                        onClick={toggleMiniPlayer}
                                        className="jw-mini-player-toggle"
                                        title={miniPlayerCollapsed ? "Déplier" : "Replier"}
                                    >
                                        <FontAwesomeIcon icon={miniPlayerCollapsed ? faChevronUp : faChevronDown} />
                                    </button>
                                    <button
                                        onClick={closeMiniPlayer}
                                        className="jw-mini-player-close"
                                        title="Fermer"
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            </div>

                            {/* Barre de progression personnalisée */}
                            <div
                                className="jw-progress-bar-container"
                                onClick={handleProgressBarClick}
                                onMouseMove={handleProgressBarHover}
                                onMouseLeave={handleProgressBarLeave}
                                title="Changer la position"
                            >
                                <div
                                    className="jw-progress-bar"
                                    style={{ width: `${progressPercentage}%` }}
                                ></div>
                                {hoverPosition !== null && (
                                    <div
                                        className="jw-progress-hover-indicator"
                                        style={{ left: `${hoverPosition}%` }}
                                    ></div>
                                )}
                            </div>

                            <div className={`jw-youtube-player-container ${miniPlayerCollapsed ? 'collapsed' : 'expanded'}`}>
                                <div id="jw-youtube-player" ref={iframeRef}></div>

                                {/* Overlay pour intercepter les clics et contrôler la lecture */}
                                <div
                                    className="jw-youtube-overlay"
                                    onClick={togglePlayback}
                                    title={isPlaying ? "Pause" : "Play"}
                                ></div>


                            </div>
                        </div>
                    )}

                    <button
                        className="jw-theme-toggle"
                        title="Changer de thème"
                        onClick={toggleDarkMode}
                    >
                        <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                    </button>

                    <div className="jw-current-date">
                        {currentDate}
                    </div>
                </div>
            </aside>

            {/* Bouton flottant pour mobile */}
            {isMobile && (
                <motion.button
                    className={`jw-floating-btn`}
                    onClick={toggleMobileMenu}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <div className="jw-btn-line"></div>
                    <div className="jw-btn-line"></div>
                    <div className="jw-btn-line"></div>
                </motion.button>
            )}

            {/* Overlay pour fermer le menu en cliquant en dehors */}
            {isMobile && mobileMenuOpen && (
                <div className="jw-mobile-overlay" onClick={toggleMobileMenu}></div>
            )}
        </>
    );
};

export default Sidebar;