import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock, faHistory, faCog, faPlay,
    faPause, faStop
} from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import { useTimer } from '../../context/TimerContext'; // Importer le hook du timer
import Card from '../../components/common/Card';
import TimerModes from '../../components/timer/TimerModes';
import TimerDisplay from '../../components/timer/TimerDisplay';
import SessionItem from '../../components/stats/SessionItem';

const Timer = () => {
    const {
        workSessions,
        settings,
        updateSettings
    } = useStorage();

    // Utiliser le contexte du timer
    const {
        seconds,
        isRunning,
        currentMode,
        progress,
        startTimer,
        pauseTimer,
        stopTimer,
        setMode
    } = useTimer();

    // Références pour les inputs de paramètres
    const workDurationRef = useRef();
    const shortBreakDurationRef = useRef();
    const longBreakDurationRef = useRef();
    const autoStartBreaksRef = useRef();
    const autoStartPomodorosRef = useRef();

    // Récupérer les sessions récentes
    const recentSessions = [...workSessions]
        .sort((a, b) => new Date(b.startTime) - new Date(a.startTime))
        .slice(0, 5);

    // Sauvegarder les paramètres
    const saveSettings = () => {
        try {
            // Récupérer les valeurs des inputs
            const workDuration = parseInt(workDurationRef.current.value || 25) * 60;
            const shortBreakDuration = parseInt(shortBreakDurationRef.current.value || 5) * 60;
            const longBreakDuration = parseInt(longBreakDurationRef.current.value || 15) * 60;
            const autoStartBreaks = autoStartBreaksRef.current.checked;
            const autoStartPomodoros = autoStartPomodorosRef.current.checked;

            // Mettre à jour les paramètres
            const newSettings = {
                workDuration,
                shortBreakDuration,
                longBreakDuration,
                autoStartBreaks,
                autoStartPomodoros
            };

            updateSettings(newSettings);
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des paramètres:", error);
        }
    };

    // Initialiser les inputs des paramètres
    useEffect(() => {
        if (workDurationRef.current) {
            workDurationRef.current.value = Math.floor(settings.workDuration / 60);
        }
        if (shortBreakDurationRef.current) {
            shortBreakDurationRef.current.value = Math.floor(settings.shortBreakDuration / 60);
        }
        if (longBreakDurationRef.current) {
            longBreakDurationRef.current.value = Math.floor(settings.longBreakDuration / 60);
        }
        if (autoStartBreaksRef.current) {
            autoStartBreaksRef.current.checked = settings.autoStartBreaks;
        }
        if (autoStartPomodorosRef.current) {
            autoStartPomodorosRef.current.checked = settings.autoStartPomodoros;
        }
    }, [settings]);

    return (
        <div>

            <div className="timer-container">
                {/* Timer Card */}
                <Card
                    title="Pomodoro Timer"
                    icon={faClock}
                    className="timer-card"
                >
                    <div className="timer-body">
                        <TimerModes currentMode={currentMode} setMode={setMode} />

                        <div className="timer-progress-container">
                            <div
                                className="timer-progress-bar"
                                style={{ width: `${progress}%` }}
                            ></div>
                        </div>

                        <TimerDisplay seconds={seconds} className={isRunning ? 'pulse' : ''} />

                        <div className="timer-controls">
                            {!isRunning ? (
                                <button
                                    className="btn btn-primary"
                                    onClick={startTimer}
                                >
                                    <FontAwesomeIcon icon={faPlay} /> Démarrer
                                </button>
                            ) : (
                                <>
                                    <button
                                        className="btn btn-warning"
                                        onClick={pauseTimer}
                                    >
                                        <FontAwesomeIcon icon={faPause} /> Pause
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => stopTimer(true)}
                                    >
                                        <FontAwesomeIcon icon={faStop} /> Arrêter
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </Card>

                {/* Sessions Card */}
                <Card
                    title="Sessions Récentes"
                    icon={faHistory}
                    className="sessions-card"
                >
                    <div className="recent-sessions-scroll">
                        <div className="stats-grid sessions-grid">
                            {recentSessions.length > 0 ? (
                                recentSessions.map(session => (
                                    <SessionItem key={session.id} session={session} />
                                ))
                            ) : (
                                <div className="empty-state">Aucune session récente</div>
                            )}
                        </div>
                    </div>
                </Card>

                {/* Timer Settings Card */}
                <Card
                    title="Paramètres"
                    icon={faCog}
                    className="settings-card"
                >
                    <div className="settings-grid">
                        <div className="setting-item">
                            <label htmlFor="work-duration">Durée de travail (minutes)</label>
                            <input
                                type="number"
                                id="work-duration"
                                ref={workDurationRef}
                                min="1"
                                max="120"
                                defaultValue="25"
                            />
                        </div>
                        <div className="setting-item">
                            <label htmlFor="short-break-duration">Pause courte (minutes)</label>
                            <input
                                type="number"
                                id="short-break-duration"
                                ref={shortBreakDurationRef}
                                min="1"
                                max="30"
                                defaultValue="5"
                            />
                        </div>
                        <div className="setting-item">
                            <label htmlFor="long-break-duration">Pause longue (minutes)</label>
                            <input
                                type="number"
                                id="long-break-duration"
                                ref={longBreakDurationRef}
                                min="5"
                                max="60"
                                defaultValue="15"
                            />
                        </div>
                        <div className="setting-item">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    id="auto-start-breaks"
                                    ref={autoStartBreaksRef}
                                />
                                Démarrer automatiquement les pauses
                            </label>
                        </div>
                        <div className="setting-item">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    id="auto-start-pomodoros"
                                    ref={autoStartPomodorosRef}
                                />
                                Démarrer automatiquement les sessions de travail
                            </label>
                        </div>
                        <div className="setting-item">
                            <button
                                id="save-settings"
                                className="btn btn-primary"
                                onClick={saveSettings}
                            >
                                Enregistrer les paramètres
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Timer;