import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faPlay, faPause, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import { useNotification } from '../../context/NotificationContext';
import Card from '../../components/common/Card';

const Playlist = () => {
    const { playlist, addVideoToPlaylist, deleteVideoFromPlaylist } = useStorage();
    const { showNotification } = useNotification();
    const [currentVideo, setCurrentVideo] = useState(
        localStorage.getItem('currentlyPlayingVideo') || null
    );
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const videoUrlRef = useRef();

    // Extraire l'ID de la vidéo YouTube depuis l'URL
    const getVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    // Obtenir le titre de la vidéo YouTube
    const getVideoTitle = async (videoId) => {
        try {
            // Essayer d'obtenir le titre via l'API oEmbed de YouTube (sans clé API)
            const response = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`);
            if (response.ok) {
                const data = await response.json();
                return data.title;
            }
        } catch (error) {
            console.log("Impossible d'obtenir le titre de la vidéo:", error);
        }

        // Titre par défaut si impossible d'obtenir le vrai titre
        return `Vidéo YouTube ${videoId}`;
    };

    // Ajouter une vidéo à la playlist
    const handleAddVideo = async () => {
        const videoUrl = videoUrlRef.current.value.trim();
        const videoId = getVideoId(videoUrl);

        if (videoId) {
            // Essayer d'obtenir le titre de la vidéo
            const videoTitle = await getVideoTitle(videoId);

            const newVideo = {
                id: Date.now(),
                videoId,
                title: videoTitle,
                addedAt: new Date().toISOString()
            };

            addVideoToPlaylist(newVideo);
            videoUrlRef.current.value = '';
            showNotification('Vidéo ajoutée à la playlist !');
        }
    };

    // Gérer l'appui sur la touche Entrée
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddVideo();
        }
    };

    // Jouer ou reprendre une vidéo
    const playVideo = (videoId, videoTitle) => {
        // Si c'est déjà la vidéo en cours, juste basculer play/pause
        if (videoId === currentVideo) {
            togglePlayback(videoId, videoTitle);
            return;
        }

        // Sinon, lancer une nouvelle vidéo
        setCurrentVideo(videoId);
        setIsPlaying(true);

        // Utiliser la fonction globale pour démarrer la vidéo dans la sidebar
        if (window.playSidebarVideo) {
            window.playSidebarVideo(videoId, videoTitle);
        }

        // Afficher une notification
        showNotification(`Lecture de "${videoTitle}"`);
    };

    // Basculer entre lecture et pause
    const togglePlayback = (videoId, videoTitle) => {
        if (isPlaying) {
            // Pause
            if (window.pauseSidebarVideo) {
                window.pauseSidebarVideo();
                setIsPlaying(false);
                showNotification(`Pause de "${videoTitle}"`);
            }
        } else {
            // Reprendre la lecture
            if (window.resumeSidebarVideo) {
                window.resumeSidebarVideo();
                setIsPlaying(true);
                showNotification(`Reprise de "${videoTitle}"`);
            }
        }
    };

    // Supprimer une vidéo de la playlist
    const handleDeleteVideo = (id, videoId) => {
        deleteVideoFromPlaylist(id);

        // Si c'est la vidéo en cours de lecture, informer la sidebar
        if (videoId === currentVideo) {
            setCurrentVideo(null);
            setIsPlaying(false);
            localStorage.removeItem('currentlyPlayingVideo');
            localStorage.removeItem('currentlyPlayingVideoTitle');

            if (window.closeSidebarPlayer) {
                window.closeSidebarPlayer();
            }
        }

        showNotification('Vidéo supprimée de la playlist !');
    };

    // Écouter les événements du lecteur YouTube
    useEffect(() => {
        const handleTimeUpdate = (event) => {
            if (event && event.detail !== undefined) {
                setCurrentTime(event.detail);
            }
        };

        const handlePlayStateChange = (event) => {
            if (event && event.detail !== undefined) {
                setIsPlaying(event.detail);
            }
        };

        const handleVideoChange = (event) => {
            if (event && event.detail) {
                setCurrentVideo(event.detail);
            }
        };

        // Ajouter les écouteurs d'événements
        window.addEventListener('youtubeTimeUpdate', handleTimeUpdate);
        window.addEventListener('youtubePlayStateChange', handlePlayStateChange);
        window.addEventListener('youtubeVideoChange', handleVideoChange);

        // Initialiser l'état de lecture depuis la sidebar
        if (window.isSidebarPlaying && typeof window.isSidebarPlaying === 'function') {
            setIsPlaying(window.isSidebarPlaying());
        }

        if (window.getSidebarCurrentTime && typeof window.getSidebarCurrentTime === 'function') {
            const time = window.getSidebarCurrentTime();
            if (time !== null) {
                setCurrentTime(time);
            }
        }

        return () => {
            // Nettoyer les écouteurs d'événements
            window.removeEventListener('youtubeTimeUpdate', handleTimeUpdate);
            window.removeEventListener('youtubePlayStateChange', handlePlayStateChange);
            window.removeEventListener('youtubeVideoChange', handleVideoChange);
        };
    }, []);

    // Formater le temps (MM:SS)
    const formatTime = (seconds) => {
        seconds = Math.floor(seconds || 0);
        const minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div>
            <div className="playlist-container">
                <Card
                    title="Playlist"
                    icon={faMusic}
                    className="playlist-card"
                >
                    <div className="playlist-input-group">
                        <input
                            type="text"
                            ref={videoUrlRef}
                            className="playlist-input"
                            placeholder="URL de la vidéo YouTube"
                            onKeyPress={handleKeyPress}
                        />
                        <button
                            className="playlist-submit"
                            onClick={handleAddVideo}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>

                    <div className="playlist-list-container">
                        {playlist.length > 0 ? (
                            <ul className="playlist-list">
                                {playlist.map(video => (
                                    <li
                                        key={video.id}
                                        className={`playlist-item ${currentVideo === video.videoId ? 'active' : ''}`}
                                        onClick={() => playVideo(video.videoId, video.title)}
                                    >
                                        <div className="playlist-play">
                                            <FontAwesomeIcon
                                                icon={currentVideo === video.videoId && isPlaying ? faPause : faPlay}
                                            />
                                        </div>
                                        <span className="playlist-title" title={video.title}>
                                            {video.title}
                                        </span>
                                        <button
                                            className="playlist-delete"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteVideo(video.id, video.videoId);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="empty-state">
                                Aucune vidéo. Ajoutez des URLs YouTube à votre playlist !
                            </div>
                        )}
                    </div>

                    <div className="playlist-info">
                        <p className="info-text">
                            <FontAwesomeIcon icon={faMusic} /> Cliquez sur une vidéo pour la lire ou la mettre en pause. La vidéo continuera à jouer même lorsque vous naviguerez dans l'application.
                        </p>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Playlist;