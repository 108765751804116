import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

// URL de base de l'API
import config from '../config';

// URL de base de l'API
const API_URL = config.apiUrl;
// Créer le contexte d'authentification
const AuthContext = createContext();

// Hook personnalisé pour utiliser le contexte d'authentification
export function useAuth() {
    return useContext(AuthContext);
}

// Fournisseur du contexte
export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('jojo_work_token'));
    const [loading, setLoading] = useState(true);

    // Vérifier si l'utilisateur est connecté au chargement
    useEffect(() => {
        const verifyToken = async () => {
            if (!token) {
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${API_URL}/verify-token`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (response.status === 200) {
                    setCurrentUser(response.data.user);
                } else {
                    // Token invalide, déconnecter l'utilisateur
                    logout();
                }
            } catch (error) {
                console.error('Erreur de vérification du token:', error);
                logout();
            }

            setLoading(false);
        };

        verifyToken();
    }, [token]);

    // Fonction de connexion
    const login = async (email, password) => {
        try {
            const response = await axios.post(`${API_URL}/login`, { email, password });
            const { token: newToken, user } = response.data;

            localStorage.setItem('jojo_work_token', newToken);
            setToken(newToken);
            setCurrentUser(user);
            return true;
        } catch (error) {
            console.error('Erreur de connexion:', error);
            throw error;
        }
    };

    // Fonction d'inscription
    const register = async (email, password, username) => {
        try {
            const response = await axios.post(`${API_URL}/register`, {
                email, password, username
            });

            const { token: newToken, user } = response.data;

            localStorage.setItem('jojo_work_token', newToken);
            setToken(newToken);
            setCurrentUser(user);
            return true;
        } catch (error) {
            console.error('Erreur d\'inscription:', error);
            throw error;
        }
    };

    // Fonction de déconnexion
    const logout = () => {
        localStorage.removeItem('jojo_work_token');
        setToken(null);
        setCurrentUser(null);
    };

    const value = {
        currentUser,
        token,
        login,
        register,
        logout,
        isAuthenticated: !!token
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}