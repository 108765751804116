import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt, faClock, faTasks, faHistory,
    faStickyNote, faPlay, faPause, faStop
} from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import { useTimer } from '../../context/TimerContext'; // Importer le hook du timer
import { formatDuration, getStartOfDay, getStartOfWeek } from '../../utils/dateUtils';
import Card from '../../components/common/Card';
import TimerModes from '../../components/timer/TimerModes';
import TimerDisplay from '../../components/timer/TimerDisplay';
import TaskItem from '../../components/tasks/TaskItem';
import SessionItem from '../../components/stats/SessionItem';
import NoteItem from '../../components/notes/NoteItem';

const Home = () => {
    const {
        workSessions,
        tasks,
        notes,
        toggleTask
    } = useStorage();

    // Utiliser le contexte du timer au lieu de la logique locale
    const {
        seconds,
        isRunning,
        currentMode,
        progress,
        startTimer,
        pauseTimer,
        stopTimer,
        setMode
    } = useTimer();

    // Calcul des statistiques
    const now = new Date();
    const startOfDay = getStartOfDay(now);
    const startOfWeek = getStartOfWeek(now);

    const todaySessions = workSessions.filter(session => new Date(session.startTime) >= startOfDay);
    const weekSessions = workSessions.filter(session => new Date(session.startTime) >= startOfWeek);

    const todayDuration = todaySessions.reduce((total, session) => total + session.duration, 0);
    const weekDuration = weekSessions.reduce((total, session) => total + session.duration, 0);

    const activeTasks = tasks.filter(task => !task.completed).length;

    // Récupérer les sessions, tâches et notes récentes
    const recentSessions = [...workSessions]
        .sort((a, b) => new Date(b.startTime) - new Date(a.startTime))
        .slice(0, 5);

    const recentTasks = [...tasks]
        .sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0))
        .slice(0, 5);

    const recentNotes = [...notes]
        .sort((a, b) => new Date(b.updatedAt || 0) - new Date(a.updatedAt || 0))
        .slice(0, 4);

    return (
        <div>
            <div className="dashboard-container">
                {/* Recent Summary */}
                <Card
                    title="Résumé"
                    icon={faTachometerAlt}
                    className="summary-card"
                >
                    <div className="stats-grid">
                        <div className="stat-item">
                            <div className="stat-title">Aujourd'hui</div>
                            <div className="stat-value">{formatDuration(todayDuration)}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Cette Semaine</div>
                            <div className="stat-value">{formatDuration(weekDuration)}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Tâches actives</div>
                            <div className="stat-value">{activeTasks}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Sessions totales</div>
                            <div className="stat-value">{workSessions.length}</div>
                        </div>
                    </div>
                </Card>

                {/* Quick Timer */}
                <Card
                    title="Chronomètre rapide"
                    icon={faClock}
                    className="mini-timer-card"
                    link={{ to: "/timer", text: "Ouvrir" }}
                >
                    <TimerModes currentMode={currentMode} setMode={setMode} />

                    <div className="timer-progress-container">
                        <div
                            className="timer-progress-bar"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>

                    <TimerDisplay seconds={seconds} />

                    <div className="timer-controls">
                        {!isRunning ? (
                            <button
                                className="btn btn-primary"
                                onClick={startTimer}
                            >
                                <FontAwesomeIcon icon={faPlay} /> Démarrer
                            </button>
                        ) : (
                            <>
                                <button
                                    className="btn btn-warning"
                                    onClick={pauseTimer}
                                >
                                    <FontAwesomeIcon icon={faPause} /> Pause
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => stopTimer(true)}
                                >
                                    <FontAwesomeIcon icon={faStop} /> Arrêter
                                </button>
                            </>
                        )}
                    </div>
                </Card>

                {/* Recent Tasks */}
                <Card
                    title="Tâches récentes"
                    icon={faTasks}
                    className="mini-tasks-card"
                    link={{ to: "/tasks", text: "Voir tout" }}
                >
                    <ul className="task-list">
                        {recentTasks.length > 0 ? (
                            recentTasks.map(task => (
                                <TaskItem
                                    key={task.id}
                                    task={task}
                                    toggleTask={toggleTask}
                                    showDelete={false}
                                />
                            ))
                        ) : (
                            <li className="empty-state">Aucune tâche. Ajoutez-en une !</li>
                        )}
                    </ul>
                </Card>

                {/* Recent Sessions */}
                <Card
                    title="Sessions récentes"
                    icon={faHistory}
                    className="mini-sessions-card"
                    link={{ to: "/stats", text: "Détails" }}
                >
                    <div className="recent-sessions-scroll">
                        <div className="stats-grid sessions-grid">
                            {recentSessions.length > 0 ? (
                                recentSessions.map(session => (
                                    <SessionItem key={session.id} session={session} />
                                ))
                            ) : (
                                <div className="empty-state">Aucune session récente</div>
                            )}
                        </div>
                    </div>
                </Card>

                {/* Quick Notes */}
                <Card
                    title="Notes récentes"
                    icon={faStickyNote}
                    className="mini-notes-card"
                    link={{ to: "/notes", text: "Voir tout" }}
                >
                    <div className="mini-notes-grid">
                        {recentNotes.length > 0 ? (
                            recentNotes.map(note => (
                                <NoteItem key={note.id} note={note} mini={true} />
                            ))
                        ) : (
                            <div className="empty-state">Aucune note. Créez votre première note !</div>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Home;