import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const NoteItem = ({ note, openEditor, deleteNote, mini = false }) => {
    const navigate = useNavigate();

    // Formater la date de mise à jour
    const formattedDate = () => {
        const date = new Date(note.updatedAt);
        return `${date.toLocaleDateString()} ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    };

    // Pour les notes miniatures (dans le tableau de bord)
    if (mini) {
        return (
            <div className="note-item" onClick={() => navigate('/notes')}>
                <div className="note-title">{note.title}</div>
                <div className="note-content">{note.content}</div>
            </div>
        );
    }

    // Pour les notes normales (dans la page Notes)
    return (
        <div className="note-item" onClick={() => openEditor ? openEditor(note.id) : null}>
            <div className="note-title">{note.title}</div>
            <div className="note-content">{note.content}</div>
            <div className="note-date">Modifié le {formattedDate()}</div>
            {openEditor && deleteNote && (
                <div className="note-actions">
                    <button
                        className="note-action"
                        onClick={(e) => {
                            e.stopPropagation();
                            openEditor(note.id);
                        }}
                        title="Éditer"
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                        className="note-action delete"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteNote(note.id);
                        }}
                        title="Supprimer"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default NoteItem;