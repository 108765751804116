import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt, faPlus,
    faChevronLeft, faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { useStorage } from '../../context/StorageContext';
import { useNotification } from '../../context/NotificationContext';
import Card from '../../components/common/Card';
import EventForm from '../../components/agenda/EventForm';

const Agenda = () => {
    const { events = [], addEvent, updateEvent, deleteEvent } = useStorage();
    const { showNotification } = useNotification();

    // État pour la date actuelle du calendrier
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentView, setCurrentView] = useState('month'); // 'month', 'week', 'day'
    const [showEventForm, setShowEventForm] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    // Obtenir l'année et le mois actuels
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Fonction pour générer les jours du mois
    const generateDaysInMonth = () => {
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
        const daysInMonth = lastDayOfMonth.getDate();

        // Obtenir le jour de la semaine du premier jour du mois (0 = dimanche, 1 = lundi, etc.)
        let firstDayOfWeek = firstDayOfMonth.getDay();
        // Ajuster pour commencer la semaine par lundi (0 = lundi, 1 = mardi, etc.)
        firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

        const days = [];

        // Ajouter les jours du mois précédent pour compléter la première semaine
        const prevMonthLastDay = new Date(currentYear, currentMonth, 0).getDate();
        for (let i = 0; i < firstDayOfWeek; i++) {
            const day = prevMonthLastDay - firstDayOfWeek + i + 1;
            days.push({
                day,
                month: currentMonth - 1,
                year: currentYear,
                isCurrentMonth: false
            });
        }

        // Ajouter les jours du mois actuel
        for (let day = 1; day <= daysInMonth; day++) {
            days.push({
                day,
                month: currentMonth,
                year: currentYear,
                isCurrentMonth: true
            });
        }

        // Ajouter les jours du mois suivant pour compléter la dernière semaine
        const remainingDays = 7 - (days.length % 7 || 7);
        for (let day = 1; day <= remainingDays; day++) {
            days.push({
                day,
                month: currentMonth + 1,
                year: currentYear,
                isCurrentMonth: false
            });
        }

        return days;
    };

    // Nouvelle fonction pour générer les jours de la semaine
    const generateWeekDays = () => {
        const weekStart = new Date(currentDate);
        // Ajuster pour commencer la semaine le lundi
        const day = weekStart.getDay();
        const diff = weekStart.getDate() - day + (day === 0 ? -6 : 1);
        weekStart.setDate(diff);

        const weekDays = [];
        for (let i = 0; i < 7; i++) {
            const day = new Date(weekStart);
            day.setDate(weekStart.getDate() + i);

            // Générer des blocs pour chaque période de la journée
            const dayEvents = events.filter(event => {
                const eventDate = new Date(event.start);
                return (
                    eventDate.getDate() === day.getDate() &&
                    eventDate.getMonth() === day.getMonth() &&
                    eventDate.getFullYear() === day.getFullYear()
                );
            });

            weekDays.push({
                date: day,
                events: dayEvents
            });
        }

        return weekDays;
    };

    const generateDayPeriods = () => {
        // Définir des périodes (matin, après-midi, soir)
        const periods = [
            {
                id: 'morning',
                label: 'Matin',
                startHour: 6,
                endHour: 12,
                info: '6h - 12h'
            },
            {
                id: 'afternoon',
                label: 'Après-midi',
                startHour: 12,
                endHour: 18,
                info: '12h - 18h'
            },
            {
                id: 'evening',
                label: 'Soirée',
                startHour: 18,
                endHour: 24,
                info: '18h - 0h'
            },
            {
                id: 'night',
                label: 'Nuit',
                startHour: 0,
                endHour: 6,
                info: '0h - 6h'
            }
        ];

        return periods.map(period => {
            const hours = [];
            for (let i = period.startHour; i < period.endHour; i++) {
                const hourEvents = events.filter(event => {
                    const eventStart = new Date(event.start);
                    return (
                        eventStart.getDate() === currentDate.getDate() &&
                        eventStart.getMonth() === currentDate.getMonth() &&
                        eventStart.getFullYear() === currentDate.getFullYear() &&
                        eventStart.getHours() === i
                    );
                });

                hours.push({
                    hour: i,
                    events: hourEvents
                });
            }

            return {
                ...period,
                hours
            };
        });
    };

    // Nouvelle fonction pour générer les heures pour la vue journalière
    const generateDayHours = () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            const hour = new Date(currentDate);
            hour.setHours(i, 0, 0, 0);
            const hourEvents = events.filter(event => {
                const eventStart = new Date(event.start);
                return (
                    eventStart.getDate() === currentDate.getDate() &&
                    eventStart.getMonth() === currentDate.getMonth() &&
                    eventStart.getFullYear() === currentDate.getFullYear() &&
                    eventStart.getHours() === i
                );
            });

            hours.push({
                hour: i,
                events: hourEvents
            });
        }
        return hours;
    };

    // Fonction pour naviguer vers le mois/semaine/jour précédent ou suivant
    const navigateDate = (direction) => {
        const newDate = new Date(currentDate);

        if (currentView === 'month') {
            newDate.setMonth(currentDate.getMonth() + direction);
        } else if (currentView === 'week') {
            newDate.setDate(currentDate.getDate() + (7 * direction));
        } else if (currentView === 'day') {
            newDate.setDate(currentDate.getDate() + direction);
        }

        setCurrentDate(newDate);
    };

    // Fonction pour changer de vue (mois, semaine, jour)
    const changeView = (view) => {
        setCurrentView(view);
    };

    // Fonction pour vérifier si une date a des événements
    const getEventsForDate = (day, month, year) => {
        return events.filter(event => {
            const eventDate = new Date(event.start);
            return (
                eventDate.getDate() === day &&
                eventDate.getMonth() === month &&
                eventDate.getFullYear() === year
            );
        });
    };

    // Fonction pour formater le mois et l'année
    const formatMonthYear = () => {
        const options = { month: 'long', year: 'numeric' };
        return currentDate.toLocaleDateString('fr-FR', options);
    };

    // Fonction pour formater le mois et l'année ou la date complète selon la vue
    const formatHeaderDate = () => {
        if (currentView === 'day') {
            // Format pour la vue jour : "Lundi 10 avril 2023"
            const options = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            };
            return currentDate.toLocaleDateString('fr-FR', options);
        } else {
            // Format pour les vues mois et semaine : "Avril 2023"
            const options = { month: 'long', year: 'numeric' };
            return currentDate.toLocaleDateString('fr-FR', options);
        }
    };

    // Fonction pour ouvrir le formulaire d'événement
    const openEventForm = (date = null, event = null) => {
        setSelectedDate(date);
        setSelectedEvent(event);
        setShowEventForm(true);
    };

    // Fonction pour fermer le formulaire d'événement
    const closeEventForm = () => {
        setShowEventForm(false);
        setSelectedEvent(null);
        setSelectedDate(null);
    };

    // Fonction pour enregistrer un événement
    const saveEvent = (eventData) => {
        if (selectedEvent) {
            // Mettre à jour un événement existant
            updateEvent(selectedEvent.id, eventData);
            showNotification('Événement mis à jour avec succès !');
        } else {
            // Ajouter un nouvel événement
            addEvent({
                id: Date.now(),
                ...eventData
            });
            showNotification('Événement ajouté avec succès !');
        }

        closeEventForm();
    };

    // Fonction pour supprimer un événement
    const handleDeleteEvent = (id) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cet événement ?')) {
            deleteEvent(id);
            showNotification('Événement supprimé !');
            closeEventForm();
        }
    };

    // Générer les jours du mois pour l'affichage du calendrier
    const daysInMonth = generateDaysInMonth();

    // Noms des jours de la semaine
    const weekDays = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];

    return (
        <div>

            <div className="agenda-container">
                <Card
                    title="Calendrier"
                    icon={faCalendarAlt}
                    className="agenda-card"
                    headerContent={
                        <div className="agenda-controls">
                            <div className="agenda-views">
                                <button
                                    className={`agenda-view-btn ${currentView === 'month' ? 'active' : ''}`}
                                    onClick={() => changeView('month')}
                                >
                                    Mois
                                </button>
                                <button
                                    className={`agenda-view-btn ${currentView === 'week' ? 'active' : ''}`}
                                    onClick={() => changeView('week')}
                                >
                                    Semaine
                                </button>
                                <button
                                    className={`agenda-view-btn ${currentView === 'day' ? 'active' : ''}`}
                                    onClick={() => changeView('day')}
                                >
                                    Jour
                                </button>
                            </div>
                            <button
                                className="btn btn-primary btn-sm add-event-btn"
                                onClick={() => openEventForm(new Date())}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Nouvel événement
                            </button>
                        </div>
                    }
                >
                    <div className="calendar-header">
                        <button className="calendar-nav-btn" onClick={() => navigateDate(-1)}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <h2 className="calendar-title">
                            {formatHeaderDate()}
                            {currentView === 'day' && currentDate.toDateString() === new Date().toDateString() && (
                                <span className="today-indicator"> (Aujourd'hui)</span>
                            )}
                        </h2>
                        <button className="calendar-nav-btn" onClick={() => navigateDate(1)}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>

                    {currentView === 'month' && (
                        <div className="calendar-grid">
                            {/* Jours de la semaine */}
                            {weekDays.map(day => (
                                <div key={day} className="calendar-weekday">
                                    {day}
                                </div>
                            ))}

                            {/* Jours du mois */}
                            {daysInMonth.map((day, index) => {
                                const dateEvents = getEventsForDate(day.day, day.month, day.year);
                                const isToday =
                                    new Date().getDate() === day.day &&
                                    new Date().getMonth() === day.month &&
                                    new Date().getFullYear() === day.year;

                                return (
                                    <div
                                        key={index}
                                        className={`calendar-day ${!day.isCurrentMonth ? 'other-month' : ''} ${isToday ? 'today' : ''}`}
                                        onClick={() => openEventForm(new Date(day.year, day.month, day.day))}
                                    >
                                        <div className="calendar-day-number">{day.day}</div>
                                        <div className="calendar-events">
                                            {dateEvents.slice(0, 3).map(event => (
                                                <div
                                                    key={event.id}
                                                    className="calendar-event"
                                                    style={{ backgroundColor: event.color || '#4f46e5' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openEventForm(null, event);
                                                    }}
                                                >
                                                    {event.title}
                                                </div>
                                            ))}
                                            {dateEvents.length > 3 && (
                                                <div className="calendar-more-events">
                                                    +{dateEvents.length - 3} plus
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {currentView === 'week' && (
                        <div className="week-view">
                            <div className="week-header">
                                <div className="week-header-cell"></div>
                                {generateWeekDays().map((day, index) => (
                                    <div key={index} className="week-header-cell">
                                        {weekDays[index]} {day.date.getDate()}
                                    </div>
                                ))}
                            </div>

                            <div className="week-timeline">
                                <div className="week-time-slots">
                                    <div className="week-time-block">
                                        <div className="week-time-label">Matin</div>
                                        <div className="week-time-sublabel">6h - 12h</div>
                                    </div>
                                    <div className="week-time-block">
                                        <div className="week-time-label">Après-midi</div>
                                        <div className="week-time-sublabel">12h - 18h</div>
                                    </div>
                                    <div className="week-time-block">
                                        <div className="week-time-label">Soirée</div>
                                        <div className="week-time-sublabel">18h - 0h</div>
                                    </div>
                                    <div className="week-time-block">
                                        <div className="week-time-label">Nuit</div>
                                        <div className="week-time-sublabel">0h - 6h</div>
                                    </div>
                                </div>

                                <div className="week-days-grid">
                                    {generateWeekDays().map((weekDay, dayIndex) => (
                                        <div key={dayIndex} className="week-day-column">
                                            {/* Bloc Matin */}
                                            <div className="week-block" onClick={() => {
                                                const date = new Date(weekDay.date);
                                                date.setHours(8, 0, 0);
                                                openEventForm(date);
                                            }}>
                                                {weekDay.events
                                                    .filter(event => {
                                                        const eventHour = new Date(event.start).getHours();
                                                        return eventHour >= 6 && eventHour < 12;
                                                    })
                                                    .map(event => {
                                                        const eventDate = new Date(event.start);
                                                        const top = ((eventDate.getHours() - 6) * 60 + eventDate.getMinutes()) / (6 * 60) * 100;
                                                        return (
                                                            <div
                                                                key={event.id}
                                                                className="week-event"
                                                                style={{
                                                                    backgroundColor: event.color || '#4f46e5',
                                                                    top: `${top}%`,
                                                                    height: '40px'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openEventForm(null, event);
                                                                }}
                                                            >
                                                                <div className="week-event-time">
                                                                    {new Date(event.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
                                                                </div>
                                                                {event.title}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>

                                            {/* Bloc Après-midi */}
                                            <div className="week-block" onClick={() => {
                                                const date = new Date(weekDay.date);
                                                date.setHours(14, 0, 0);
                                                openEventForm(date);
                                            }}>
                                                {weekDay.events
                                                    .filter(event => {
                                                        const eventHour = new Date(event.start).getHours();
                                                        return eventHour >= 12 && eventHour < 18;
                                                    })
                                                    .map(event => {
                                                        const eventDate = new Date(event.start);
                                                        const top = ((eventDate.getHours() - 12) * 60 + eventDate.getMinutes()) / (6 * 60) * 100;
                                                        return (
                                                            <div
                                                                key={event.id}
                                                                className="week-event"
                                                                style={{
                                                                    backgroundColor: event.color || '#4f46e5',
                                                                    top: `${top}%`,
                                                                    height: '40px'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openEventForm(null, event);
                                                                }}
                                                            >
                                                                <div className="week-event-time">
                                                                    {new Date(event.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
                                                                </div>
                                                                {event.title}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>

                                            {/* Bloc Soirée */}
                                            <div className="week-block" onClick={() => {
                                                const date = new Date(weekDay.date);
                                                date.setHours(20, 0, 0);
                                                openEventForm(date);
                                            }}>
                                                {weekDay.events
                                                    .filter(event => {
                                                        const eventHour = new Date(event.start).getHours();
                                                        return eventHour >= 18 && eventHour < 24;
                                                    })
                                                    .map(event => {
                                                        const eventDate = new Date(event.start);
                                                        const top = ((eventDate.getHours() - 18) * 60 + eventDate.getMinutes()) / (6 * 60) * 100;
                                                        return (
                                                            <div
                                                                key={event.id}
                                                                className="week-event"
                                                                style={{
                                                                    backgroundColor: event.color || '#4f46e5',
                                                                    top: `${top}%`,
                                                                    height: '40px'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openEventForm(null, event);
                                                                }}
                                                            >
                                                                <div className="week-event-time">
                                                                    {new Date(event.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
                                                                </div>
                                                                {event.title}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>

                                            {/* Bloc Nuit */}
                                            <div className="week-block" onClick={() => {
                                                const date = new Date(weekDay.date);
                                                date.setHours(2, 0, 0);
                                                openEventForm(date);
                                            }}>
                                                {weekDay.events
                                                    .filter(event => {
                                                        const eventHour = new Date(event.start).getHours();
                                                        return eventHour >= 0 && eventHour < 6;
                                                    })
                                                    .map(event => {
                                                        const eventDate = new Date(event.start);
                                                        const top = (eventDate.getHours() * 60 + eventDate.getMinutes()) / (6 * 60) * 100;
                                                        return (
                                                            <div
                                                                key={event.id}
                                                                className="week-event"
                                                                style={{
                                                                    backgroundColor: event.color || '#4f46e5',
                                                                    top: `${top}%`,
                                                                    height: '40px'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openEventForm(null, event);
                                                                }}
                                                            >
                                                                <div className="week-event-time">
                                                                    {new Date(event.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
                                                                </div>
                                                                {event.title}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {currentView === 'day' && (
                        <div className="day-view">

                            <div className="day-content">
                                {generateDayPeriods().map(period => (
                                    <div key={period.id} className="day-period">
                                        <div className="day-period-header">
                                            <div className="day-period-label">{period.label}</div>
                                            <div className="day-period-info">{period.info}</div>
                                        </div>

                                        <div className="day-blocks">
                                            {period.hours.map(hourSlot => (
                                                <div key={hourSlot.hour} className="day-block">
                                                    <div className="day-hour">
                                                        {`${String(hourSlot.hour).padStart(2, '0')}:00`}
                                                    </div>
                                                    <div
                                                        className="day-events"
                                                        onClick={() => {
                                                            const hourDate = new Date(currentDate);
                                                            hourDate.setHours(hourSlot.hour, 0, 0, 0);
                                                            openEventForm(hourDate);
                                                        }}
                                                    >
                                                        {hourSlot.events.map(event => (
                                                            <div
                                                                key={event.id}
                                                                className="day-event"
                                                                style={{ backgroundColor: event.color || '#4f46e5' }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openEventForm(null, event);
                                                                }}
                                                            >
                                                                <div className="day-event-time">
                                                                    {new Date(event.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
                                                                    {event.end && ` - ${new Date(event.end).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`}
                                                                </div>
                                                                <div className="day-event-title">{event.title}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </Card>
            </div>

            {/* Formulaire d'événement modal */}
            {showEventForm && (
                <EventForm
                    onClose={closeEventForm}
                    onSave={saveEvent}
                    onDelete={selectedEvent ? () => handleDeleteEvent(selectedEvent.id) : null}
                    event={selectedEvent}
                    initialDate={selectedDate}
                />
            )}
        </div>
    );
};

export default Agenda;