// StorageContext.js
import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useNotification } from './NotificationContext';
import config from '../config';

// URL de base de l'API
const API_URL = config.apiUrl;

// Créer le contexte de stockage
const StorageContext = createContext();

// Hook personnalisé pour utiliser le contexte de stockage
export function useStorage() {
    return useContext(StorageContext);
}

// Fournisseur du contexte
export function StorageProvider({ children }) {
    const { token } = useAuth();
    const { showNotification } = useNotification();

    // États pour chaque type de données
    const [workSessions, setWorkSessions] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [notes, setNotes] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [events, setEvents] = useState([]);
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [settings, setSettings] = useState({
        workDuration: 25 * 60,
        shortBreakDuration: 5 * 60,
        longBreakDuration: 15 * 60,
        autoStartBreaks: false,
        autoStartPomodoros: false
    });

    // Configuration axios avec token
    const api = axios.create({
        baseURL: API_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });

    // Fonctions de chargement des données de fichiers et dossiers
    const fetchFiles = useCallback(async () => {
        try {
            const response = await api.get('/files');
            console.log("Fichiers chargés:", response.data);
            setFiles(response.data);
            return response.data;
        } catch (error) {
            console.error('Erreur lors du chargement des fichiers:', error);
            showNotification('Erreur lors du chargement des fichiers');
            return [];
        }
    }, [api, showNotification]);

    const fetchFolders = useCallback(async () => {
        try {
            const response = await api.get('/folders');
            console.log("Dossiers chargés:", response.data);
            setFolders(response.data);
            return response.data;
        } catch (error) {
            console.error('Erreur lors du chargement des dossiers:', error);
            showNotification('Erreur lors du chargement des dossiers');
            return [];
        }
    }, [api, showNotification]);

    const fetchFilesInFolder = useCallback(async (folderId) => {
        try {
            const url = folderId === null
                ? `${API_URL}/files`
                : `${API_URL}/files?folderId=${folderId}`;

            const response = await api.get(url);
            console.log(`Fichiers dans le dossier ${folderId || 'racine'}:`, response.data);
            // Ne pas mettre à jour tous les fichiers, mais retourner ceux du dossier
            return response.data;
        } catch (error) {
            console.error(`Erreur lors du chargement des fichiers du dossier ${folderId}:`, error);
            showNotification('Erreur lors du chargement des fichiers');
            return [];
        }
    }, [api, showNotification]);

    const fetchFoldersInFolder = useCallback(async (parentFolderId) => {
        try {
            const url = parentFolderId === null
                ? `${API_URL}/folders?parentFolderId=null`
                : `${API_URL}/folders?parentFolderId=${parentFolderId}`;

            const response = await api.get(url);
            console.log(`Sous-dossiers dans ${parentFolderId || 'racine'}:`, response.data);
            // Ne pas mettre à jour tous les dossiers, mais retourner ceux du parent
            return response.data;
        } catch (error) {
            console.error(`Erreur lors du chargement des sous-dossiers de ${parentFolderId}:`, error);
            showNotification('Erreur lors du chargement des dossiers');
            return [];
        }
    }, [api, showNotification]);

    // Rafraîchir à la fois les fichiers et les dossiers
    const refreshStorage = useCallback(async () => {
        setIsLoading(true);
        try {
            await Promise.all([fetchFiles(), fetchFolders()]);
        } finally {
            setIsLoading(false);
        }
    }, [fetchFiles, fetchFolders]);

    // Charger les données depuis l'API au démarrage
    useEffect(() => {
        if (!token) return;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                console.log("Début du chargement des données...");

                // Chargement des données
                const [
                    sessionsResponse,
                    tasksResponse,
                    notesResponse,
                    playlistResponse,
                    eventsResponse,
                    filesResponse,
                    foldersResponse,
                    settingsResponse
                ] = await Promise.all([
                    api.get('/work-sessions').catch(e => ({ data: [] })),
                    api.get('/tasks').catch(e => ({ data: [] })),
                    api.get('/notes').catch(e => ({ data: [] })),
                    api.get('/playlist').catch(e => ({ data: [] })),
                    api.get('/events').catch(e => ({ data: [] })),
                    api.get('/files'),
                    api.get('/folders'),
                    api.get('/settings').catch(e => ({ data: {} }))
                ]);

                console.log("Fichiers reçus:", filesResponse.data);
                console.log("Dossiers reçus:", foldersResponse.data);

                setWorkSessions(sessionsResponse.data);
                setTasks(tasksResponse.data);
                setNotes(notesResponse.data);
                console.log("Playlist reçue:", playlistResponse.data);
                setPlaylist(playlistResponse.data);
                setEvents(eventsResponse.data);
                setFiles(filesResponse.data);
                setFolders(foldersResponse.data);

                // Convertir les paramètres au format attendu par l'application
                const receivedSettings = settingsResponse.data;
                if (receivedSettings && Object.keys(receivedSettings).length > 0) {
                    setSettings({
                        workDuration: receivedSettings.work_duration || 25 * 60,
                        shortBreakDuration: receivedSettings.short_break_duration || 5 * 60,
                        longBreakDuration: receivedSettings.long_break_duration || 15 * 60,
                        autoStartBreaks: !!receivedSettings.auto_start_breaks,
                        autoStartPomodoros: !!receivedSettings.auto_start_pomodoros
                    });
                }

                console.log("Données chargées avec succès!");
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error);
                showNotification('Erreur lors du chargement des données');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    // Fonctions pour le stockage de fichiers
    const uploadFile = async (fileData) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', fileData.file);

            // Au lieu d'ajouter folderId dans FormData, l'ajouter à l'URL
            let url = `${API_URL}/files`;
            if (fileData.folderId !== null && fileData.folderId !== undefined) {
                url += `?folderId=${fileData.folderId}`;
            }

            console.log(`Uploading file to URL: ${url}`);

            const response = await axios({
                method: 'post',
                url: url, // Utilise l'URL avec le paramètre folderId
                data: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            const newFile = response.data;

            // S'assurer que le fichier a toutes les propriétés nécessaires pour l'affichage
            const completeFile = {
                ...newFile,
                // Assurer la compatibilité avec les deux formats possibles
                folder_id: newFile.folder_id !== undefined ? newFile.folder_id : newFile.folderId,
                folderId: newFile.folder_id !== undefined ? newFile.folder_id : newFile.folderId,
                file_path: newFile.file_path || newFile.path
            };

            // Mettre à jour l'état des fichiers
            setFiles(prev => {
                // Vérifier si le fichier existe déjà (éviter les doublons)
                const exists = prev.some(f => f.id === completeFile.id);
                if (exists) {
                    return prev.map(f => f.id === completeFile.id ? completeFile : f);
                } else {
                    return [...prev, completeFile];
                }
            });

            return completeFile;
        } catch (error) {
            console.error('Erreur lors de l\'upload du fichier:', error);
            showNotification('Erreur lors de l\'upload du fichier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };
    const deleteFile = async (fileId) => {
        try {
            setIsLoading(true);
            await api.delete(`/files/${fileId}`);
            setFiles(prev => prev.filter(file => file.id !== fileId));
            showNotification('Fichier supprimé avec succès');
        } catch (error) {
            console.error('Erreur lors de la suppression du fichier:', error);
            showNotification('Erreur lors de la suppression du fichier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const moveFile = async (fileId, folderId) => {
        try {
            setIsLoading(true);
            await api.put(`/files/${fileId}/move`, {
                folderId: folderId === null ? null : folderId
            });

            // Rafraîchir les fichiers après le déplacement
            await fetchFiles();

            showNotification('Fichier déplacé avec succès');
            return { success: true };
        } catch (error) {
            console.error('Erreur lors du déplacement du fichier:', error);
            showNotification('Erreur lors du déplacement du fichier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const renameFile = async (fileId, newName) => {
        try {
            setIsLoading(true);
            const response = await api.put(`/files/${fileId}/rename`, { name: newName });
            setFiles(prev => prev.map(file =>
                file.id === fileId ? { ...file, name: newName } : file
            ));
            showNotification('Fichier renommé avec succès');
            return response.data;
        } catch (error) {
            console.error('Erreur lors du renommage du fichier:', error);
            showNotification('Erreur lors du renommage du fichier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const createFolder = async (name, parentFolderId = null) => {
        try {
            setIsLoading(true);
            const response = await api.post('/folders', {
                name,
                parentFolderId: parentFolderId === null ? null : parentFolderId
            });

            const newFolder = response.data;

            // Ajouter le nouveau dossier à l'état
            setFolders(prev => [...prev, newFolder]);

            showNotification('Dossier créé avec succès');
            return newFolder;
        } catch (error) {
            console.error('Erreur lors de la création du dossier:', error);
            showNotification('Erreur lors de la création du dossier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const deleteFolder = async (folderId, recursive = true) => {
        try {
            setIsLoading(true);
            await api.delete(`/folders/${folderId}${recursive ? '?recursive=true' : ''}`);

            // Mise à jour de l'état des dossiers
            if (recursive) {
                // Si suppression récursive, supprimer tous les sous-dossiers
                const foldersToRemove = getAllDescendantFolderIds(folders, folderId);
                foldersToRemove.push(folderId);

                setFolders(prev => prev.filter(folder => !foldersToRemove.includes(folder.id)));

                // Supprimer aussi tous les fichiers contenus dans ces dossiers
                setFiles(prev => prev.filter(file => {
                    const fileFolderId = file.folder_id !== undefined ? file.folder_id : file.folderId;
                    return !foldersToRemove.includes(fileFolderId);
                }));
            } else {
                // Suppression simple
                setFolders(prev => prev.filter(folder => folder.id !== folderId));

                // Supprimer les fichiers de ce dossier
                setFiles(prev => prev.filter(file => {
                    const fileFolderId = file.folder_id !== undefined ? file.folder_id : file.folderId;
                    return fileFolderId !== folderId;
                }));
            }

            showNotification('Dossier supprimé avec succès');
        } catch (error) {
            console.error('Erreur lors de la suppression du dossier:', error);
            showNotification('Erreur lors de la suppression du dossier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    // Fonction utilitaire pour trouver tous les ID de dossiers descendants
    function getAllDescendantFolderIds(folders, rootFolderId) {
        const result = [];
        const queue = [rootFolderId];

        while (queue.length > 0) {
            const currentId = queue.shift();

            // Trouver tous les sous-dossiers directs
            const childFolders = folders.filter(folder => folder.parent_folder_id === currentId);

            for (const child of childFolders) {
                result.push(child.id);
                queue.push(child.id); // Ajouter ce dossier pour explorer ses propres enfants
            }
        }

        return result;
    }

    const moveFolder = async (folderId, parentFolderId) => {
        try {
            setIsLoading(true);
            await api.put(`/folders/${folderId}/move`, {
                parentFolderId: parentFolderId === null ? null : parentFolderId
            });

            // Rafraîchir les dossiers après le déplacement
            await fetchFolders();

            showNotification('Dossier déplacé avec succès');
            return { success: true };
        } catch (error) {
            console.error('Erreur lors du déplacement du dossier:', error);
            showNotification('Erreur lors du déplacement du dossier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const renameFolder = async (folderId, newName) => {
        try {
            setIsLoading(true);
            const response = await api.put(`/folders/${folderId}/rename`, { name: newName });

            // Mettre à jour l'état des dossiers
            setFolders(prev => prev.map(folder =>
                folder.id === folderId ? { ...folder, name: newName } : folder
            ));

            showNotification('Dossier renommé avec succès');
            return response.data;
        } catch (error) {
            console.error('Erreur lors du renommage du dossier:', error);
            showNotification('Erreur lors du renommage du dossier');
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    // Fonctions pour les sessions de travail, les tâches, etc.
    const addWorkSession = async (session) => {
        try {
            const response = await api.post('/work-sessions', session);
            const newSession = {
                ...session,
                id: response.data.id
            };
            setWorkSessions(prev => [...prev, newSession]);
            return newSession;
        } catch (error) {
            console.error('Erreur lors de l\'ajout d\'une session:', error);
            showNotification('Erreur lors de l\'enregistrement de la session');
            throw error;
        }
    };

    const addTask = async (task) => {
        try {
            const response = await api.post('/tasks', task);
            const newTask = {
                ...task,
                id: response.data.id
            };
            setTasks(prev => [...prev, newTask]);
            return newTask;
        } catch (error) {
            console.error('Erreur lors de l\'ajout d\'une tâche:', error);
            showNotification('Erreur lors de l\'ajout de la tâche');
            throw error;
        }
    };

    const toggleTask = async (id) => {
        try {
            const taskToToggle = tasks.find(task => task.id === id);
            if (!taskToToggle) return;

            await api.put(`/tasks/${id}`, {
                completed: !taskToToggle.completed
            });

            setTasks(prev => prev.map(task =>
                task.id === id ? { ...task, completed: !task.completed } : task
            ));
        } catch (error) {
            console.error('Erreur lors de la modification d\'une tâche:', error);
            showNotification('Erreur lors de la modification de la tâche');
            throw error;
        }
    };

    const deleteTask = async (id) => {
        try {
            await api.delete(`/tasks/${id}`);
            setTasks(prev => prev.filter(task => task.id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression d\'une tâche:', error);
            showNotification('Erreur lors de la suppression de la tâche');
            throw error;
        }
    };

    const addNote = async (note) => {
        try {
            const response = await api.post('/notes', note);
            const newNote = response.data;
            setNotes(prev => [...prev, newNote]);
            return newNote;
        } catch (error) {
            console.error('Erreur lors de l\'ajout d\'une note:', error);
            showNotification('Erreur lors de l\'ajout de la note');
            throw error;
        }
    };

    const updateNote = async (id, updatedNote) => {
        try {
            const response = await api.put(`/notes/${id}`, updatedNote);
            const newNote = response.data;
            setNotes(prev => prev.map(note =>
                note.id === id ? newNote : note
            ));
            return newNote;
        } catch (error) {
            console.error('Erreur lors de la mise à jour d\'une note:', error);
            showNotification('Erreur lors de la mise à jour de la note');
            throw error;
        }
    };

    const deleteNote = async (id) => {
        try {
            await api.delete(`/notes/${id}`);
            setNotes(prev => prev.filter(note => note.id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression d\'une note:', error);
            showNotification('Erreur lors de la suppression de la note');
            throw error;
        }
    };

    const addVideoToPlaylist = async (video) => {
        try {
            const response = await api.post('/playlist', {
                videoId: video.videoId,
                title: video.title
            });
            const newVideo = {
                ...video,
                id: response.data.id
            };
            setPlaylist(prev => [...prev, newVideo]);
            return newVideo;
        } catch (error) {
            console.error('Erreur lors de l\'ajout d\'une vidéo:', error);
            showNotification('Erreur lors de l\'ajout de la vidéo');
            throw error;
        }
    };

    const deleteVideoFromPlaylist = async (id) => {
        try {
            await api.delete(`/playlist/${id}`);
            setPlaylist(prev => prev.filter(video => video.id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression d\'une vidéo:', error);
            showNotification('Erreur lors de la suppression de la vidéo');
            throw error;
        }
    };

    const addEvent = async (event) => {
        try {
            const response = await api.post('/events', event);
            const newEvent = response.data;
            setEvents(prev => [...prev, newEvent]);
            return newEvent;
        } catch (error) {
            console.error('Erreur lors de l\'ajout d\'un événement:', error);
            showNotification('Erreur lors de l\'ajout de l\'événement');
            throw error;
        }
    };

    const updateEvent = async (id, updatedEvent) => {
        try {
            const response = await api.put(`/events/${id}`, updatedEvent);
            const newEvent = response.data;
            setEvents(prev => prev.map(event =>
                event.id === id ? newEvent : event
            ));
            return newEvent;
        } catch (error) {
            console.error('Erreur lors de la mise à jour d\'un événement:', error);
            showNotification('Erreur lors de la mise à jour de l\'événement');
            throw error;
        }
    };

    const deleteEvent = async (id) => {
        try {
            await api.delete(`/events/${id}`);
            setEvents(prev => prev.filter(event => event.id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression d\'un événement:', error);
            showNotification('Erreur lors de la suppression de l\'événement');
            throw error;
        }
    };

    const updateSettings = async (newSettings) => {
        try {
            // Convertir les paramètres au format attendu par l'API
            await api.put('/settings', newSettings);
            setSettings(prev => ({ ...prev, ...newSettings }));
        } catch (error) {
            console.error('Erreur lors de la mise à jour des paramètres:', error);
            showNotification('Erreur lors de la mise à jour des paramètres');
            throw error;
        }
    };

    // Objet de valeur qui sera fourni par le contexte
    const value = {
        workSessions,
        tasks,
        notes,
        playlist,
        events,
        settings,
        files,
        folders,
        isLoading,
        // Fonctions de chargement
        fetchFiles,
        fetchFolders,
        fetchFilesInFolder,
        fetchFoldersInFolder,
        refreshStorage,
        // Fonctions pour les sessions, tâches, etc.
        addWorkSession,
        addTask,
        toggleTask,
        deleteTask,
        addNote,
        updateNote,
        deleteNote,
        addVideoToPlaylist,
        deleteVideoFromPlaylist,
        addEvent,
        updateEvent,
        deleteEvent,
        updateSettings,
        // Fonctions pour les fichiers
        uploadFile,
        deleteFile,
        moveFile,
        renameFile,
        // Fonctions pour les dossiers
        createFolder,
        deleteFolder,
        moveFolder,
        renameFolder
    };

    return (
        <StorageContext.Provider value={value}>
            {children}
        </StorageContext.Provider>
    );
}